import { useEffect } from "react";

// prettier-ignore
type Callback = <T,>(...args: []) => Promise<T> | Promise<void> | T | void;

export function useMount(callback: Callback) {
  return useEffect(() => {
    (async () => await callback())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
