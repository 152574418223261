import { Formik, FormikProps, useFormikContext } from "formik";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PrimaryFormButton } from "../../../components/Buttons";
import {
  Email,
  Phone,
  TextOrCall,
  useSenderValues,
} from "../../../hooks/senders";
import { useMount } from "../../../hooks/useMount";
import { useReactRouterRedirect } from "../../../hooks/useReactRouterRedirect";
import { useUsername } from "../../../hooks/username";
import { getSenders, sendLoginPinCode } from "../../../requests";
import { SelectEmail } from "../../Delivery/SelectEmail";
import { SelectPhone } from "../../Delivery/SelectPhone";
import { SelectTextOrCall } from "../../Delivery/SelectTextOrCall";
import { redirectTo } from "../../../utils/redirectHelper";
import { useAppConfig } from "../../../hooks/appConfig";
import { LoginLayout } from "../Login/LoginLayout";
import { MobileLayoutWithActionBar } from "../../../MobileLayoutWithActionBar";

export function DeliveryPicker(props: { associateExistingAccount?: boolean }) {
  const reactRouterRedirect = useReactRouterRedirect();
  const { t } = useTranslation();
  const [usernameOrCorpEmail] = useUsername();
  const [, setSenderValues] = useSenderValues();
  const [senders, setSenders] = useState(getInitialState());
  const { myPwCEntryPoint } = useAppConfig();
  const history = useHistory();
  const formikRef = useRef<FormikProps<any>>(null);

  useMount(async () => {
    try {
      const response = await getSenders(usernameOrCorpEmail);
      setSenders(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        redirectTo(myPwCEntryPoint);
      }
    }
  });

  return (
    <MobileLayoutWithActionBar
      title={t("Request Access Code")}
      prevButtonText={t("Back")}
      nextButtonText={t("Next")}
      disableNextButton={
        !!(formikRef.current && formikRef.current.isSubmitting)
      }
      onClickPrev={() => history.goBack()}
      onClickNext={() => {
        if (formikRef.current) {
          formikRef.current.submitForm();
        }
      }}
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={{
          phone: !!senders.primaryPhoneNumber
            ? Phone.primaryPhoneNumber
            : Phone.email,
          textOrCall: TextOrCall.message,
          email: Email.primaryEmail,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSenderValues(values);
          const isSendingByEmail = values.phone === Phone.email;
          const sendThrough = isSendingByEmail
            ? Phone.email
            : values.textOrCall;
          const sender = isSendingByEmail ? values.email : values.phone;
          await sendLoginPinCode(usernameOrCorpEmail, sender, sendThrough);
          setSubmitting(false);
          reactRouterRedirect(
            props.associateExistingAccount
              ? "/journey/verify-and-associate"
              : "/journey/verify"
          );
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <LoginLayout>
              {!!senders.primaryPhoneNumber && (
                <SelectPhone senders={senders} />
              )}
              {values.phone === Phone.email ? (
                <SelectEmail {...senders} />
              ) : (
                <SelectTextOrCall senders={senders} />
              )}
            </LoginLayout>
          );
        }}
      </Formik>
    </MobileLayoutWithActionBar>
  );
}

function getInitialState() {
  return {
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  };
}
