import React from "react";
import styled from "@emotion/styled";
import { secondaryGrey4 } from "../../styles/variables";

export function Separator(props: { children: string }) {
  return (
    <SeparatorContainer>
      <Line />
      <Text>{props.children}</Text>
      <Line />
    </SeparatorContainer>
  );
}

const SeparatorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const Line = styled.div`
  height: 0;
  border-bottom: 1px solid ${secondaryGrey4};
  flex-grow: 1;
`;

const Text = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 5px 24px;
`;
