import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../components/H2";
import { TextField } from "../../components/TextField";
import { Layout } from "../../Layout";
import { verifyProxyInviteTicket } from "../../requests";
import { validateEmail } from "../../utils/validateEmail";
import {
  ButtonContainer,
  FormButton,
  FormContainer,
  FormGroup,
  getInviteTicket,
} from "./common";
import { ProgressIndicator } from "./ProgressIndicator";
import { TextFieldFloating } from "../../components/TextFieldFloating";

export function ProxyVerifyAccessCodeStep(props: {
  verify: (e: string, a: string) => void;
}) {
  const { t } = useTranslation();
  const validate = useValidation();

  return (
    <Layout height={620} width={960}>
      <Formik
        initialValues={{ email: "", accessCode: "" }}
        validate={validate}
        onSubmit={async (
          { email, accessCode },
          { setSubmitting, setFieldError }
        ) => {
          const response = await verifyProxyInviteTicket(
            getInviteTicket(),
            email,
            accessCode
          );
          setSubmitting(false);
          if (response.data) {
            props.verify(email, accessCode);
          } else {
            setFieldError(
              "accessCode",
              t("Incorrect Email Address or Access Code")
            );
          }
        }}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <FormContainer>
            <ProgressIndicator />

            <FormGroup>
              <H2>{t("Access Code")}</H2>

              <TextFieldFloating name="email" nonFloating>
                {t("Your Email Address")}
              </TextFieldFloating>
              <TextFieldFloating name="accessCode" nonFloating>
                {t("Access Code")}
              </TextFieldFloating>
            </FormGroup>

            <ButtonContainer>
              <FormButton
                data-testid="button-verify-access-code"
                type="submit"
                disabled={isSubmitting}
              >
                {t("Verify")}
              </FormButton>
            </ButtonContainer>
          </FormContainer>
        )}
      </Formik>
    </Layout>
  );
}

function useValidation() {
  const { t } = useTranslation();

  return (values: { email: string }) => {
    if (!values.email)
      return {
        email: t("{field} is required", { field: "Your Email Address" }),
      };
    if (validateEmail(values.email)) return {};
    return { email: t("Invalid email format") };
  };
}
