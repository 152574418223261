import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../../components/H2";
import { Phone, useSenderValues } from "../../../hooks/senders";
import { useMount } from "../../../hooks/useMount";
import { useUsername } from "../../../hooks/username";
import { getSenders } from "../../../requests";
import { Group } from "./components";
import { redirectTo } from "../../../utils/redirectHelper";
import { useAppConfig } from "../../../hooks/appConfig";
import lockImgSrc from "../../../images/lock.png";
import styled from "@emotion/styled";

export function Headline() {
  const { t } = useTranslation();
  const [username] = useUsername();
  const [senders, setSenders] = useState(getInitialState());
  const [senderValues] = useSenderValues();
  const { myPwCEntryPoint } = useAppConfig();

  useMount(async () => {
    const response = await getSenders(username);
    setSenders(response.data);
  });

  const senderText =
    senderValues.phone === Phone.email
      ? senders[senderValues.email!]
      : senders[senderValues.phone!];

  if (
    Object.keys(senderValues).length === 0 &&
    senderValues.constructor === Object
  ) {
    redirectTo(myPwCEntryPoint);
  }

  return (
    <>
      <Hint>
        <p>
          {t(
            "For your security, we sent a one-time access code to {sender}. If you did not receive the code you can select a different delivery method.",
            { sender: senderText }
          )}
        </p>
      </Hint>
    </>
  );
}

const Hint = styled(Group)`
  margin: 10px 0 20px 0;
`;

function getInitialState() {
  return {
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  };
}
