import { useHistory } from "react-router-dom";

export function useReactRouterRedirect() {
  const history = useHistory();

  return function (path: string, state?: {} | null | undefined) {
    if (path.indexOf("?") === -1) {
      history.push(`${path}${window.location.search}`, state);
    } else {
      history.push(path, state);
    }
  };
}
