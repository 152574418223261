export function validateEmail(email: string) {
  return allCharValid(email) && !isEmailInvalid(email) && isEmailValid(email);
}

function allCharValid(value: string) {
  const validChars =
    "1234567890qwertyuiop!#$&+='asdfghjklzxcvbnm.@-_QWERTYUIOPASDFGHJKLZXCVBNM";
  return value.split("").every((c) => validChars.indexOf(c) > -1);
}

function isEmailValid(email: string) {
  const validEmail = /^.+@(\[?)[a-zA-Z0-9\-_.]+\.([a-zA-Z][a-zA-Z0-9]{0,14})(]?)$/;
  return validEmail.test(email);
}

function isEmailInvalid(value: string) {
  const reg = /(@.*@)|(\.\.)|(^\.)|(^@)|(@$)|(\.$)|(@\.)/;
  return reg.test(value);
}
