import styled from "@emotion/styled";
import {
  secondaryTealMed,
  primaryTeal,
  primaryBlack,
  secondaryGrey2,
  secondaryGrey4,
  secondaryTealLight,
} from "../styles/variables";

const Button = styled.button`
  height: 48px;
  padding: 10px 24px;
  outline: none;
  border-radius: 24px;
  touch-action: manipulation;
  font-size: 16px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  border: none;
`;

export const PrimaryButton = styled(Button)`
  color: ${primaryBlack};
  background: ${primaryTeal};

  &:enabled {
    &:hover {
      background-color: ${secondaryTealLight};
    }
    &:active {
      color: white;
      background-color: ${secondaryTealMed};
    }
  }
  &:disabled {
    color: ${secondaryGrey4};
    background-color: ${secondaryGrey2};
    border: none;
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(Button)`
  color: white;
  background: ${primaryBlack};
  border: 1px solid white;
  &:enabled {
    &:hover {
      color: ${primaryBlack};
      background-color: ${secondaryTealLight};
    }
    &:active {
      color: white;
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: ${secondaryTealMed};
    }
  }
  &:disabled {
    color: ${secondaryGrey4};
    border: 1px solid ${secondaryGrey2};
    cursor: not-allowed;
  }
`;

export const PrimaryFormButton = styled(PrimaryButton)`
  margin: 16px 0;
  width: 100%;
`;

export const SecondaryFormButton = styled(SecondaryButton)`
  margin-bottom: 8px;
  width: 100%;
`;
