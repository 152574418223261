import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import loginLogoImgSrc from "../../images/login-logo.svg";
import { MyMobilityH2 } from "../../components/MyMobilityH2";

export const HeadlineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 34px;
`;

const Img = styled.img`
  width: 108px;
  height: 108px;
  flex-shrink: 0;
  margin-bottom: 24px;
`;

export function Headline() {
  const { t } = useTranslation();

  return (
    <HeadlineContainer>
      <Img src={loginLogoImgSrc} alt="myMobility" />
      <MyMobilityH2>Welcome to myMobility</MyMobilityH2>
    </HeadlineContainer>
  );
}
