import { css } from "@emotion/core";
import {
  appBackgroundColor,
  secondaryGrey2,
  fontSans,
  largerFontSize,
  linkHoverColor,
  primaryWhite,
  mainFontSize,
  mainLineHeight,
  secondaryTealMed,
  primaryTeal,
} from "./variables";
import aeonikBold from "../fonts/aeonik/Aeonik-Bold.woff";
import aeonikLight from "../fonts/aeonik/Aeonik-Light.woff";
import aeonikMedium from "../fonts/aeonik/Aeonik-Medium.woff";
import aeonikRegular from "../fonts/aeonik/Aeonik-Regular.woff";
import aeonikBoldItalic from "../fonts/aeonik/Aeonik-BoldItalic.woff";
import aeonikLightItalic from "../fonts/aeonik/Aeonik-LightItalic.woff";
import aeonikMediumItalic from "../fonts/aeonik/Aeonik-MediumItalic.woff";
import aeonikRegularItalic from "../fonts/aeonik/Aeonik-RegularItalic.woff";

export const globalStyles = css`
  body {
    font-feature-settings: "tnum";
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 200;
    src: url(${aeonikLightItalic}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: normal;
    font-weight: 900;
    src: url(${aeonikBold}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 900;
    src: url(${aeonikBoldItalic}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: normal;
    font-weight: 700;
    src: url(${aeonikBold}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 700;
    src: url(${aeonikBoldItalic}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: normal;
    font-weight: 300;
    src: url(${aeonikLight}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 300;
    src: url(${aeonikLightItalic}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: normal;
    font-weight: 500;
    src: url(${aeonikMedium}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 500;
    src: url(${aeonikMediumItalic}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: normal;
    font-weight: 400;
    src: url(${aeonikRegular}) format("woff");
  }

  @font-face {
    font-display: swap;
    font-family: Aeonik;
    font-style: italic;
    font-weight: 400;
    src: url(${aeonikRegularItalic}) format("woff");
  }

  #root {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  menu,
  nav,
  section {
    display: block;
  }

  html {
    overflow-y: auto;
    height: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    height: 100%;
    font-family: ${fontSans};
    font-size: ${mainFontSize};
    color: ${primaryWhite};
    margin: 0;
    line-height: ${mainLineHeight};
    background-color: ${appBackgroundColor};
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  input {
    border-style: solid;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
  }

  a {
    outline-style: none;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      // color: ${linkHoverColor};
    }
  }

  h1 {
    font-size: 35px;
    &.white {
      font-size: 21px;
      color: white;
    }
  }

  h2 {
    font-family: ${fontSans};
    font-size: ${largerFontSize};
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: ${mainFontSize};
  }

  p {
    font-size: ${mainFontSize};
  }

  .MuiRadio-colorSecondary,
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${secondaryTealMed};
  }

  .MuiCheckbox-root.MuiRadio-colorSecondary.Mui-checked {
    &&&&& {
      color: ${secondaryTealMed};
    }
  }

  .MuiTooltip-arrow::before {
    border: 1px solid ${secondaryGrey2};
  }
`;
