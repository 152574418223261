import React, { ReactNode } from "react";
import { Form } from "formik";
import styled from "@emotion/styled";

export function LoginLayout(props: { children: ReactNode }) {
  return <Layout>{props.children}</Layout>;
}

const Layout = styled(Form)`
  width: 100%;
`;
