import styled from "@emotion/styled";
import { Field, useFormikContext } from "formik";
import React, { ReactNode, useCallback, useState } from "react";
import {
  errorColor,
  primaryTeal,
  statusRed3,
  smallerFontSize, MediaQueryMobile,
} from "../styles/variables";
import { useShowError } from "../hooks/showError";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Tooltip } from "./Tooltip";
import clsx from "clsx";
import eyeClose from "../images/eye-close.svg";
import eyeOpen from "../images/eye-open.svg";

export function TextFieldFloating(props: {
  name: string;
  children: ReactNode;
  type?: "text" | "password" | string;
  helpText?: string;
  stretch?: boolean;
  autoFocus?: boolean;
  nonFloating?: boolean;
  centeredLabel?: boolean;
  width?: number;
  warningMessage?: string;
}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const labelClasses = useLabelStyles();
  const labelClassname = clsx(
    props.nonFloating && labelClasses.labelNonFloating,
    props.centeredLabel && labelClasses.labelCentered
  );
  const fieldType = props.type;
  const fieldName = props.name;
  const fieldhelpText = props.helpText;
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();
  const [showError] = useShowError();
  const fieldError = errors[fieldName];
  const fieldTouched = touched[fieldName];
  const handleClickShowPassword = useCallback(
    () => setPasswordShown(!passwordShown),
    [passwordShown]
  );
  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  return (
    <Container
      stretch={props.stretch}
      width={props.width}
      className="text-field-container"
    >
      <Field name={props.name}>
        {({ field, form }: any) => (
          <StyledFormControl
            fullWidth
            error={fieldTouched && showError && Boolean(fieldError)}
          >
            <InputLabel htmlFor={fieldName} className={labelClassname}>
              <span>{props.children}</span>
              {fieldhelpText && <Tooltip helpText={fieldhelpText} />}
            </InputLabel>
            {props.warningMessage && (
              <WarningMessage>{props.warningMessage}</WarningMessage>
            )}
            {fieldType === "password" ? (
              <Input
                {...field}
                id={fieldName}
                autoComplete="off"
                autoFocus={props.autoFocus}
                type={passwordShown ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordShown ? (
                        <HidePasswordIcon />
                      ) : (
                        <ShowPasswordIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <Input
                {...field}
                id={fieldName}
                autoComplete="off"
                autoFocus={props.autoFocus}
                type={fieldType}
              />
            )}

            <FormHelperText>{fieldTouched && fieldError}</FormHelperText>
          </StyledFormControl>
        )}
      </Field>
    </Container>
  );
}

const styles = createStyles({
  root: {
    "& label": {
      display: "flex",
      "align-items": "center",
      color: "#fff",
      "font-family": "inherit",

      "&.Mui-focused": {
        color: "#fff",
      },
      "&.Mui-error": {
        color: "#fff",
      },
      "& > span": {
        "margin-right": "24px",
      },
    },
    "& .MuiInput-underline": {
      color: "#fff",
      "font-family": "inherit",

      "&:before": {
        borderBottomColor: "#fff",
      },
      "&:after": {
        borderBottomColor: primaryTeal,
      },

      "&:hover": {
        "&:before": {
          borderBottom: "1px solid #fff",
        },
      },
      "&.Mui-error": {
        "&:before": {
          borderBottomColor: "#F3AEAF",
        },
        "&:after": {
          borderBottomColor: "#F3AEAF",
        },
      },

      "& button": {
        color: "#fff",
      },
      "& input": {
        "font-weight": 600,
      },
    },
    "& .MuiFormHelperText-root": {
      "font-family": "inherit",
      "font-size": "1rem",

      "&.Mui-error": {
        color: statusRed3,
      },
    },
  },
});

const useLabelStyles = makeStyles({
  labelNonFloating: {
    transform: "translate(0, 0) scale(1)",
    "transform-origin": "top left",

    "&.MuiInputLabel-shrink": {
      transform: "translate(0, 0) scale(1)",
    },
    "& + .MuiInput-formControl": {
      marginTop: "18px",
    },
  },
  labelCentered: {
    width: "100%",
    "justify-content": "center",
    "& > span": {
      marginRight: "0!important",
    },
  },
});

const StyledFormControl = withStyles(styles)(FormControl);

const Container = styled.div<{ stretch?: boolean; width?: number }>`
  flex: ${(props) => (props.stretch ? 1 : "0 0 auto")};
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  justify-items: center;
  align-items: flex-start;
  margin: 0 auto;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: white !important;
  }
  
  ${MediaQueryMobile} {
    width: 100%;
  }
`;

const Icon = styled.img`
  color: #fff;
`;

const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: ${smallerFontSize};
  font-style: italic;
  line-height: normal;
  margin: 30px 0 8px 0;
  color: ${errorColor};
`;

const ShowPasswordIcon = () => (
  <Icon src={eyeClose} data-testid="show-password-icon"></Icon>
);
const HidePasswordIcon = () => (
  <Icon src={eyeOpen} data-testid="hide-password-icon"></Icon>
);
