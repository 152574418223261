import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Layout } from "../../Layout";
import { PrimaryFormButton } from "../../components/Buttons";
import { sendResetPasswordEmail } from "../../requests";
import { CenteredForm } from "../../components/CenteredForm";
import { Phone } from "../../hooks/senders";
import styled from "@emotion/styled";
import { useCountDown } from "../../hooks/useCountDown";
import { useAppConfig } from "../../hooks/appConfig";
import { primaryTeal, secondaryTealLight } from "../../styles/variables";
import lockImgSrc from "../../images/lock.png";
import { brandingConfig } from "../../branding/config";
import { TextFieldFloating } from "../../components/TextFieldFloating";

export function ForgetPassword() {
  const { t } = useTranslation();
  const [showEmailSentMessage, setShowEmailSentMessage] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { resetPasswordInterval } = useAppConfig();
  const [onCountingDown, setOnCountingDown, countingDownNumber] = useCountDown(
    resetPasswordInterval
  );

  const url = new URL(window.location.href);
  const isPinCodeExpired = !!url.searchParams.get("isPinCodeExpired");
  const isLinkExpired = !!url.searchParams.get("isLinkExpired");
  const isLocked = !!url.searchParams.get("isLocked");
  let remindMessage = "";
  if (isLinkExpired || (isPinCodeExpired && !isLocked)) {
    remindMessage = t(
      "Your request to reset password has already expired or been previously used. Please try again."
    );
  }

  if (!isPinCodeExpired && isLocked) {
    remindMessage = t(
      "Your account has been locked because you have reached the maximum login attempts. Enter your username or email address to reset your password again."
    );
  }
  if (buttonClicked) {
    remindMessage = t("");
  }

  return (
    <Layout>
      <Formik
        initialValues={{ emailAddress: "", username: "" }}
        onSubmit={async ({ emailAddress, username }, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const response = await sendResetPasswordEmail(
              emailAddress,
              Phone.email,
              username.trim(),
              ""
            );
            if (response.status === 200) {
              setShowEmailSentMessage(true);
              setSubmitting(false);
              setOnCountingDown(true);
              setButtonClicked(true);
            }
          } catch (e) {
            if (e.response.status === 409) {
              setShowUsername(true);
            }
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, values }) => (
          <CenteredForm>
            <Header>
              <Img src={lockImgSrc} alt="myMobility" />
              <H2>{t("Reset Password")}</H2>
            </Header>
            {!showEmailSentMessage && !showUsername && (
              <P>
                {t(
                  "We will send you a one time access link to complete your reset. Enter below your email address to receive it."
                )}
              </P>
            )}
            {!showEmailSentMessage && showUsername && (
              <P>
                {t(
                  "Please enter your username to continue. If you forget your username, please contact {company} for support.",
                  { company: brandingConfig.company }
                )}
              </P>
            )}
            <TextFieldFloating
              name="emailAddress"
              nonFloating={true}
              warningMessage={remindMessage}
            >
              {t("Email Address")}
            </TextFieldFloating>
            {showUsername && (
              <TextFieldFloating name="username">
                {t("Username")}
              </TextFieldFloating>
            )}
            {onCountingDown ? (
              <CountDownP>
                {t(
                  `You can click here to resend the email in {countingDownNumber} seconds.`,
                  { countingDownNumber: countingDownNumber }
                )}
              </CountDownP>
            ) : buttonClicked ? (
              <SubmitLink type="submit" data-testid="resend-email-link">
                {t("Please click here to resend the email")}
              </SubmitLink>
            ) : showUsername ? (
              <PrimaryFormButton
                type="submit"
                disabled={
                  isSubmitting ||
                  !values.emailAddress ||
                  (showUsername && !values.username)
                }
                data-testid="button-send-reset-psw"
              >
                {t("Send")}
              </PrimaryFormButton>
            ) : (
              <StyledPrimaryFormButton
                type="submit"
                disabled={isSubmitting || !values.emailAddress}
                data-testid="button-send-reset-psw"
              >
                {t("Next")}
              </StyledPrimaryFormButton>
            )}
            {showEmailSentMessage && (
              <P>
                {t(
                  "If your account exists, you will receive an email to reset your password. The email will expire in 24 hours."
                )}
              </P>
            )}
          </CenteredForm>
        )}
      </Formik>
    </Layout>
  );
}

const P = styled.p`
  margin: 24px 0;
  font-size: 16px;
`;

const CountDownP = styled.p`
  font-size: 16px;
  text-align: center;
  margin-top: 4px;
`;

const H2 = styled.h2`
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600;
`;

const SubmitLink = styled.button`
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: ${primaryTeal};
  cursor: pointer;
  margin-top: 4px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    color: ${secondaryTealLight};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

const Img = styled.img`
  width: 56px;
  height: 56px;
`;

const StyledPrimaryFormButton = styled(PrimaryFormButton)`
  position: relative;
  left: 50%;
  margin-left: -152px;
  width: 304px;
`;
