import styled from "@emotion/styled";
import React from "react";
import {
  primaryBlack,
  statusGreen3,
  statusRed3,
  zIndexHigh,
} from "../styles/variables";
import { PrimaryFormButton } from "./Buttons";
import { redirectTo } from "../utils/redirectHelper";
import { CancelOutlined, CheckCircleOutline } from "@material-ui/icons";

export function MessagePopup(props: {
  title: string;
  content: string;
  returnUrl: string;
  buttonText: string;
  failed?: boolean;
}) {
  return (
    <WhiteOverlay>
      <PopupContainer>
        <PopupBody>
          {props.failed ? <CancelCircle /> : <CheckCircle />}
          <Title>{props.title}</Title>
          <ContentContainer>
            <p dangerouslySetInnerHTML={{ __html: props.content }} />
          </ContentContainer>
          <Footer>
            <PrimaryFormButton onClick={() => redirectTo(props.returnUrl)}>
              {props.buttonText}
            </PrimaryFormButton>
          </Footer>
        </PopupBody>
      </PopupContainer>
    </WhiteOverlay>
  );
}

const WhiteOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${zIndexHigh};
  top: 0;
  left: 0;
  opacity: 1;
`;

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: start;
  z-index: ${zIndexHigh};
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: calc(100vw - 30px);
  overflow: hidden;
  background-color: #fff;
  color: ${primaryBlack};
`;

const CheckCircle = styled(CheckCircleOutline)`
  height: auto !important;
  width: auto !important;
  max-width: 50px;
  max-height: 50px;
  color: ${statusGreen3};
  font-size: 50px;
  margin-top: 20px;
`;

const CancelCircle = styled(CancelOutlined)`
  height: auto !important;
  width: auto !important;
  max-width: 50px;
  max-height: 50px;
  color: ${statusRed3};
  font-size: 50px;
  margin-top: 20px;
`;

const Title = styled.h2`
  font-weight: 700;
  padding: 20px 30px;
`;

const ContentContainer = styled.div`
  text-align: left;
  max-width: 600px;

  p {
    padding: 0 30px;
  }
`;

const Footer = styled.div`
  border: none;
  padding: 30px;
`;
