import React from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "../../components/Radio";
import { Email } from "../../hooks/senders";
import { Group } from "./Group";
import { P } from "./common";

export function SelectEmail(props: {
  primaryEmail: string;
  secondaryEmail: string;
}) {
  const { t } = useTranslation();

  return (
    <Group>
      <P>
        {t(
          "We can also send the access code to your email. Which email should we send the access code to?"
        )}
      </P>

      <Radio name="email" value={Email.primaryEmail}>
        {props.primaryEmail}
      </Radio>
      {props.secondaryEmail && (
        <Radio name="email" value={Email.secondaryEmail}>
          {props.secondaryEmail}
        </Radio>
      )}
    </Group>
  );
}
