import axios from "axios";
import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { getTemplates } from "../requests";
import { useMount } from "./useMount";
import { useCurrentLanguage } from "./useCurrentLanguage";

type Announcement = {
  announcement: {
    announcementContent: string;
    shouldShowAnnouncement: boolean;
  };
  announcementDispatch: Function;
};
export const announcementPage = "MyPwCAnnouncementPage";

export enum AnnouncementActionType {
  SHOW = "SHOW",
  HIDE = "HIDE",
  UPDATE_CONTENT = "UPDATE_CONTENT",
}

const announcementReducer = (
  state: any,
  action: { type: AnnouncementActionType; payload: string }
) => {
  switch (action.type) {
    case AnnouncementActionType.SHOW:
      return { ...state, shouldShowAnnouncement: true };
    case AnnouncementActionType.HIDE:
      return { ...state, shouldShowAnnouncement: false };
    case AnnouncementActionType.UPDATE_CONTENT:
      return { ...state, announcementContent: action.payload };
  }
  return state;
};

export const AnnouncementContext = createContext<Announcement>({
  announcement: {
    announcementContent: "",
    shouldShowAnnouncement: false,
  },
  announcementDispatch: () => {},
});

export function useAnnouncementBanner() {
  return useContext(AnnouncementContext);
}

export function AnnouncementBannerProvider(props: { children: ReactNode }) {
  const currentCulture = useCurrentLanguage()?.value || "en-US";
  const initialAnnouncement = {
    announcementContent: "",
    shouldShowAnnouncement: false,
  };
  const [announcement, announcementDispatch] = React.useReducer(
    announcementReducer,
    initialAnnouncement
  );
  async function requestTemplate(culture: string) {
    try {
      const response = await getTemplates(announcementPage, culture);
      if (!response || response.status !== 200) {
        return;
      }

      const template = response.data;
      if (!template || !template.templates) return;

      announcementDispatch({
        type: AnnouncementActionType.UPDATE_CONTENT,
        payload: template.templates.Body,
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        throw e;
      }
    }
  }

  useEffect(() => {
    (async function () {
      await requestTemplate(currentCulture);
    })();
  }, [currentCulture]);

  useMount(async () => {
    await requestTemplate(currentCulture);
  });

  return (
    <AnnouncementContext.Provider
      value={{
        announcement,
        announcementDispatch,
      }}
    >
      {props.children}
    </AnnouncementContext.Provider>
  );
}
