import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Phone, useSenderValues } from "../../hooks/senders";
import { useMount } from "../../hooks/useMount";
import { useUsername } from "../../hooks/username";
import { getSenders } from "../../requests";
import { Group } from "./components";
import { redirectTo } from "../../utils/redirectHelper";
import { useAppConfig } from "../../hooks/appConfig";
import lockImgSrc from "../../images/lock.png";
import styled from "@emotion/styled";
import {MediaQueryMobile, secondaryTealLight} from "../../styles/variables";

export function Headline() {
  const { t } = useTranslation();
  const [username] = useUsername();
  const [senders, setSenders] = useState(getInitialState());
  const [senderValues] = useSenderValues();
  const { myPwCEntryPoint } = useAppConfig();

  useMount(async () => {
    const response = await getSenders(username);
    setSenders(response.data);
  });

  const senderText =
    senderValues.phone === Phone.email
      ? senders[senderValues.email!]
      : senders[senderValues.phone!];

  if (
    Object.keys(senderValues).length === 0 &&
    senderValues.constructor === Object
  ) {
    redirectTo(myPwCEntryPoint);
  }

  return (
    <>
      <Header>
        <Img src={lockImgSrc} alt="myMobility" />
        <h2>{t("Access Code Sent")}</h2>
      </Header>
      <Group>
        <p>
          {t(
            "For your security, we sent a one-time access code to {sender}. If you did not receive the code you can select a different delivery method.",
            { sender: senderText }
          )}
        </p>
      </Group>
    </>
  );
}

function getInitialState() {
  return {
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  };
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 15px;

    ${MediaQueryMobile} {
      font-size: 24px;
    }
  }
`;

const SenderText = styled.span`
  color: ${secondaryTealLight};
`;

const Img = styled.img`
  width: 56px;
  margin-bottom: 10px;
`;
