export const secondaryTealMed = "#0A838A";
export const primaryTeal = "#1cdbbc";
export const secondaryTealLight = "#8BF5E3";

export const statusRed1 = "#ffefef";
export const statusRed3 = "#e5484d";
export const statusRed4 = "#cd2b31";
export const statusYellow1 = "#FFFBD1";
export const statusYellow3 = "#f7d90a";

export const statusGreen3 = "#46a758";
export const statusGreen4 = "#297c3b";
export const statusGreenLighter = "#ebf9eb";

export const primaryBlack = "#000000";
export const primaryWhite = "#ffffff";

export const secondaryGrey1 = "#f7f5f5";
export const secondaryGrey2 = "#eae9e8";
export const secondaryGrey4 = "#c2c2c2";

export const appBackgroundColor = primaryBlack;
export const linkHoverColor = primaryTeal;
export const errorColor = statusRed3;
export const mainFontSize = "14px";
export const minWidth = "1366px";
export const mainLineHeight = 1.44;
export const fontSans = "Aeonik, Arial, sans-serif";
export const fontSerif = "Georgia, serif";
export const largerFontSize = "24px";
export const smallFontSize = "13px";
export const smallerFontSize = "12px";
export const zIndexHigh = 300;
export const mobileWidthBreakpoint = 768;
export const MediaQueryMobile = `@media (max-width: ${mobileWidthBreakpoint}px)`;
export const MediaQueryPC = `@media (min-width: ${mobileWidthBreakpoint}px)`;