import React, { Suspense, useState } from "react";
import { MobileLayout as Layout } from "../../../MobileLayout";
import { LoginStepSSO } from "./LoginStepSSO";
import { getUrlParameter } from "../../../utils/urlHelper";

export function Login() {
  const corpEmail = getUrlParameter("corpEmail");

  return (
    <Layout>
      <Suspense fallback="">
        <LoginStepSSO corpEmail={corpEmail} isInactive={!!corpEmail} />
      </Suspense>
    </Layout>
  );
}
