import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "../../hooks/useMount";
import { setLocalStorage } from "../../utils/storage";
import { AssigneeRegistration } from "./AssigneeRegistration";
import { getCultureCode, getIsProxy } from "./common";
import { ProxyRegistration } from "./ProxyRegistration";

export function Registration() {
  const { i18n } = useTranslation();
  const [isProxy] = useState(getIsProxy());

  useMount(async () => {
    if (getCultureCode()) {
      await i18n.changeLanguage(getCultureCode());
      setLocalStorage("culture", getCultureCode());
    }
  });

  if (isProxy) return <ProxyRegistration />;
  return <AssigneeRegistration />;
}
