import { Formik } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUsername } from "../../hooks/username";
import { getHasSSOAssociated } from "../../requests";
import { LoginLayout } from "./LoginLayout";
import { LoginSteps } from "../../constants";
import { TextField } from "../../components/TextField";
import { useValidation } from "./useValidation";
import { ForgetPasswordLink } from "./ForgetPasswordLink";
import _ from "lodash";
import { PrimaryFormButton } from "../../components/Buttons";
import { useMount } from "../../hooks/useMount";
import { useHasClientAssociation } from "../../hooks/hasClientAssociation";
import {
  AnnouncementActionType,
  useAnnouncementBanner,
} from "../../hooks/useAnnouncementBanner";
import { TextFieldFloating } from "../../components/TextFieldFloating";
import styled from "@emotion/styled";
import { Headline } from "./Headline";

export function LoginStepStart(props: {
  setStep: Dispatch<SetStateAction<LoginSteps>>;
}) {
  const [, setUsernameOrCorpEmail] = useUsername();
  const { t } = useTranslation();
  const { validateUsername } = useValidation();
  const validate = (values: { usernameOrCorpEmail: string }) => {
    const errors: { [key: string]: string } = {};
    validateUsername(values.usernameOrCorpEmail, errors);
    return errors;
  };
  const [, setHasClientAssociation] = useHasClientAssociation();
  const { announcementDispatch } = useAnnouncementBanner();
  useEffect(() => {
    return function cleanup() {
      announcementDispatch({
        type: AnnouncementActionType.HIDE,
      });
    };
  }, [announcementDispatch]);

  useMount(() => {
    announcementDispatch({
      type: AnnouncementActionType.SHOW,
    });
  });
  return (
    <Formik
      initialValues={{ usernameOrCorpEmail: "" }}
      validate={validate}
      onSubmit={async ({ usernameOrCorpEmail }, { setSubmitting }) => {
        usernameOrCorpEmail = _.trim(usernameOrCorpEmail);
        setSubmitting(false);
        const hasSSOAssociated = (
          await getHasSSOAssociated(usernameOrCorpEmail)
        ).data;
        setHasClientAssociation(hasSSOAssociated);
        if (hasSSOAssociated) {
          setUsernameOrCorpEmail(usernameOrCorpEmail);
          props.setStep(LoginSteps.SSOLogin);
          return;
        }
        setUsernameOrCorpEmail(usernameOrCorpEmail);
        props.setStep(LoginSteps.NormalLoginWithSSO);
      }}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <LoginLayout>
          <Headline />
          <TextFieldFloating name="usernameOrCorpEmail" width={400}>
            {t("Username")}
          </TextFieldFloating>
          <LoginButton
            type="submit"
            disabled={isSubmitting}
            data-testid="button-next-on-login-start"
          >
            {t("Next")}
          </LoginButton>
          <ForgetPasswordLink />
        </LoginLayout>
      )}
    </Formik>
  );
}

const LoginButton = styled(PrimaryFormButton)`
  display: block;
  margin: 4px auto 24px;
  width: 244px;
  height: 48px;
`;
