import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryFormButton } from "../../components/Buttons";
import { CenteredForm } from "../../components/CenteredForm";
import { Email, Phone, TextOrCall, useSenderValues } from "../../hooks/senders";
import { useMount } from "../../hooks/useMount";
import { useReactRouterRedirect } from "../../hooks/useReactRouterRedirect";
import { useUsername } from "../../hooks/username";
import { Layout } from "../../Layout";
import { getSenders, sendLoginPinCode } from "../../requests";
import { Headline } from "./Headline";
import { SelectEmail } from "./SelectEmail";
import { SelectPhone } from "./SelectPhone";
import { SelectTextOrCall } from "./SelectTextOrCall";
import { redirectTo } from "../../utils/redirectHelper";
import { useAppConfig } from "../../hooks/appConfig";

export function DeliveryPicker(props: { associateExistingAccount?: boolean }) {
  const reactRouterRedirect = useReactRouterRedirect();
  const { t } = useTranslation();
  const [usernameOrCorpEmail] = useUsername();
  const [, setSenderValues] = useSenderValues();
  const [senders, setSenders] = useState(getInitialState());
  const { myPwCEntryPoint } = useAppConfig();

  useMount(async () => {
    try {
      const response = await getSenders(usernameOrCorpEmail);
      setSenders(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        redirectTo(myPwCEntryPoint);
      }
    }
  });

  const isUSNumber = senders.primaryPhoneNumber.startsWith('+1') || (senders.secondaryPhoneNumber && senders.secondaryPhoneNumber.startsWith('+1'));

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          phone: !!senders.primaryPhoneNumber
            ? Phone.primaryPhoneNumber
            : Phone.email,
          textOrCall: isUSNumber ? TextOrCall.voiceCall : TextOrCall.message,
          email: Email.primaryEmail,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSenderValues(values);
          const isSendingByEmail = values.phone === Phone.email;
          const sendThrough = isSendingByEmail
            ? Phone.email
            : values.textOrCall;
          const sender = isSendingByEmail ? values.email : values.phone;
          await sendLoginPinCode(usernameOrCorpEmail, sender, sendThrough);
          setSubmitting(false);
          reactRouterRedirect(
            props.associateExistingAccount ? "/verify-and-associate" : "/verify"
          );
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <CenteredForm>
              <Headline />
              {!!senders.primaryPhoneNumber && (
                <SelectPhone senders={senders} />
              )}
              {values.phone === Phone.email ? (
                <SelectEmail {...senders} />
              ) : (
                <SelectTextOrCall senders={senders} />
              )}
              <PrimaryFormButton
                className="submit-button"
                type="submit"
                disabled={isSubmitting}
                data-testid="button-send-verify-code"
              >
                {t("Send")}
              </PrimaryFormButton>
            </CenteredForm>
          );
        }}
      </Formik>
    </Layout>
  );
}

function getInitialState() {
  return {
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  };
}
