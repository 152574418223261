import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  primaryBlack,
  statusGreen3,
  secondaryTealLight,
} from "../../styles/variables";
import React from "react";

export function ProgressIndicator() {
  const { t } = useTranslation();

  return (
    <>
      <CircleContainer>
        <Circle isActive />
        <Line />
        <Circle />
        <Line />
        <Circle />
      </CircleContainer>
      <Container>
        <P isActive>{t("Create Account")}</P>
        <P>{t("Sign Consents")}</P>
        <P>{t("Confirm Personal Details")}</P>
      </Container>
    </>
  );
}

const Line = styled.div`
  width: 90px;
  border-top: 1px solid white;
  align-self: center;
  margin: 0 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6px;
`;

const CircleContainer = styled(Container)`
  margin-top: 30px;
`;

type IsActive = { isActive?: boolean };

const P = styled.div<IsActive>`
  width: 150px;
  text-align: center;
  color: ${(props) => (props.isActive ? secondaryTealLight : "white")};
`;

const Circle = styled.div<IsActive>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.isActive ? secondaryTealLight : "white")};
`;
