import PinField from "react-pin-field";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import {
  MediaQueryMobile,
  primaryWhite,
  statusRed3,
} from "../styles/variables";
import { Field, useFormikContext } from "formik";
import FormControl from "@material-ui/core/FormControl";
import { useShowError } from "../hooks/showError";
import FormHelperText from "@material-ui/core/FormHelperText";

export function AccessCodeField(props: { name: string }) {
  const { t } = useTranslation();
  const fieldName = props.name;
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();
  const [showError] = useShowError();
  const fieldError = errors[fieldName];
  const fieldTouched = touched[fieldName];
  const currentRef = useRef<HTMLInputElement[]>([]);
  useEffect(() => {
    currentRef.current[0].focus();
  }, []);

  return (
    <Field name={props.name}>
      {({ form }: any) => (
        <FormControl
          fullWidth
          error={fieldTouched && showError && Boolean(fieldError)}
        >
          <AccessCodeLabel>{t("Access Code")}</AccessCodeLabel>
          <PinFieldContainer>
            <PinField
              type="number"
              ref={currentRef}
              length={6}
              validate={/^[0-9]$/}
              className={"pin-field"}
              onChange={(values) => {
                form.setFieldValue("accessCode", values);
              }}
            />
          </PinFieldContainer>
          <StyledFormHelperText>
            {fieldTouched && fieldError}
          </StyledFormHelperText>
        </FormControl>
      )}
    </Field>
  );
}

const PIN_FIELD_DESKTOP_WIDTH = 54;
const PIN_FIELD_DESKTOP_MARGIN_RIGHT = 20;
const PIN_FIELD_MOBILE_WIDTH = 30;
const PIN_FIELD_MOBILE_MARGIN_RIGHT = 12;

const calculatePinFieldTotalWidth = (
  pinFieldInputWidth: number,
  marginRight: number
): number => {
  const paddingX = 4;
  return 6 * (pinFieldInputWidth + paddingX) + 5 * marginRight;
};

const desktopPinFieldTotalWidth = calculatePinFieldTotalWidth(
  PIN_FIELD_DESKTOP_WIDTH,
  PIN_FIELD_DESKTOP_MARGIN_RIGHT
);
const mobilePinFieldTotalWidth = calculatePinFieldTotalWidth(
  PIN_FIELD_MOBILE_WIDTH,
  PIN_FIELD_MOBILE_MARGIN_RIGHT
);

const PinFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  .pin-field {
    color: ${primaryWhite};
    width: ${PIN_FIELD_DESKTOP_WIDTH}px;
    font-size: 40px !important;
    background: transparent;
    caret-color: ${primaryWhite};
    text-align: center;
    border: none;
    border-bottom: 1px solid ${primaryWhite};
    border-radius: 0;
    outline: none;
    &:not(:last-child) {
      margin-right: ${PIN_FIELD_DESKTOP_MARGIN_RIGHT}px;
    }

    ${MediaQueryMobile} {
      width: ${PIN_FIELD_MOBILE_WIDTH}px;
      font-size: 24px !important;

      &:not(:last-child) {
        margin-right: ${PIN_FIELD_MOBILE_MARGIN_RIGHT}px;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
`;

const AccessCodeLabel = styled.span`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
`;

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    font-family: inherit;
    font-size: 1rem;

    &.Mui-error {
      color: ${statusRed3};
      margin: 0 calc((100% - ${desktopPinFieldTotalWidth}px) / 2);
    }

    ${MediaQueryMobile} {
      &.Mui-error {
        margin: 0 calc((100% - ${mobilePinFieldTotalWidth}px) / 2);
      }
    }
  }
`;
