import Url from "url-parse";
import _ from "lodash";

export function verifyRedirectUrl(
  returnUrl: string,
  redirectWhiteList?: string,
  redirectErrorUrl?: string
): string {
  if (!redirectWhiteList || !redirectErrorUrl) {
    return "https://" + window.location.host;
  }

  if (!returnUrl || returnUrl.startsWith("//")) {
    return redirectErrorUrl;
  }

  const verifyUrl = returnUrl.toLowerCase();
  let url;
  try {
    url = new Url(verifyUrl);
  } catch {
    return returnUrl;
  }
  const urlHost = url.hostname;
  const whiteList = redirectWhiteList.toLowerCase().split(";");
  let status = false;
  whiteList.some((domain) => {
    if (_.endsWith(urlHost, domain)) {
      status = true;
    }
  });
  return status ? returnUrl : redirectErrorUrl;
}
