import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const HasClientAssociationContext = createContext<Ctx<boolean>>([
  true,
  () => {},
]);

export function useHasClientAssociation(): Ctx<boolean> {
  const [hasClientAssociation, setHasClientAssociation] = useContext(
    HasClientAssociationContext
  );
  return [hasClientAssociation, setHasClientAssociation];
}

export function HasClientAssociationProvider(props: { children: ReactNode }) {
  const [hasClientAssociation, setHasClientAssociation] = useState(false);

  return (
    <HasClientAssociationContext.Provider
      value={[hasClientAssociation, setHasClientAssociation]}
    >
      {props.children}
    </HasClientAssociationContext.Provider>
  );
}
