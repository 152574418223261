import styled from "@emotion/styled";
import { Radio as MuiRadio } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import React from "react";
import {
  primaryBlack,
  primaryTeal,
  secondaryTealLight,
} from "../styles/variables";

export function Radio(props: {
  name: string;
  value: string;
  children: string;
}) {
  const { values } = useFormikContext();
  const currentValue = (values as any)?.[props.name];

  const id = `${props.name}-${props.value}`;
  return (
    <RadioContainer>
      <Field name={props.name}>
        {({ field }: any) => (
          <R
            {...field}
            id={id}
            value={props.value}
            checked={props.value === currentValue}
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
          />
        )}
      </Field>
      <Label htmlFor={id}>{props.children}</Label>
    </RadioContainer>
  );
}

const Label = styled.label`
  cursor: pointer;
`;

const R = styled(MuiRadio)`
  color: ${secondaryTealLight} !important;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 10px 0;
`;

const CheckedIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${primaryTeal};
  border-radius: 100%;
  background: ${primaryBlack};
  position: relative;
  box-sizing: border-box;
  &::after {
    content: "";
    width: 9px;
    height: 9px;
    background: ${primaryTeal};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    transition: all 0.2s ease;
  }
`;

const UncheckedIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid ${primaryTeal};
  box-sizing: border-box;
`;
