import React from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "../../components/Radio";
import { Senders } from "../../requests";
import { Group } from "./Group";
import { Phone } from "../../hooks/senders";
import { P } from "./common";

export function SelectPhone(props: { senders: Senders }) {
  const { t } = useTranslation();

  return (
    <Group>
      <P>{t("Which phone should we send the access code to?")}</P>

      <Radio name="phone" value={Phone.primaryPhoneNumber}>
        {props.senders.primaryPhoneNumber}
      </Radio>
      {props.senders.secondaryPhoneNumber && (
        <Radio name="phone" value={Phone.secondaryPhoneNumber}>
          {props.senders.secondaryPhoneNumber}
        </Radio>
      )}
      <Radio name="phone" value={Phone.email}>
        {t("Send to my email")}
      </Radio>
    </Group>
  );
}
