import React, { useState } from "react";
import { useAppConfig } from "../../hooks/appConfig";
import { useMount } from "../../hooks/useMount";
import { verifyInviteTicket } from "../../requests";
import { redirectTo } from "../../utils/redirectHelper";
import { getInviteTicket } from "./common";
import { ProxyVerifyAccessCodeStep } from "./ProxyAccessCodeStep";
import { ProxyRegistrationStep } from "./ProxyRegistrationStep";

export function ProxyRegistration() {
  const { myPwCEntryPoint } = useAppConfig();
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");

  function verify(e: string, a: string) {
    setVerified(true);
    setEmail(e);
    setAccessCode(a);
  }

  useMount(async () => {
    const verifyResponse = await verifyInviteTicket(getInviteTicket());
    if (!verifyResponse.data) redirectTo(myPwCEntryPoint);
  });

  return verified && email ? (
    <ProxyRegistrationStep email={email} accessCode={accessCode} />
  ) : (
    <ProxyVerifyAccessCodeStep verify={verify} />
  );
}
