import React from "react";
import styled from "@emotion/styled";
import {
  primaryTeal,
  primaryWhite,
  secondaryTealLight,
  smallerFontSize,
} from "../../../styles/variables";
import { useTranslation } from "react-i18next";
import { useCopyrightLinks } from "../../../hooks/appConfig";

export function MobileFooter() {
  const { t } = useTranslation();
  const { copyrightLink } = useCopyrightLinks();
  return (
    <Footer>
      <P>
        <>&copy; 2022 Vialto Partners. </>
        <span>{t("All rights reserved. Please see")} </span>
        <CopyRightA href={copyrightLink} data-testid="structure-link">
          {" "}
          vialto.com/copyright{" "}
        </CopyRightA>
        <span>{t("for further details.")}</span>
      </P>
    </Footer>
  );
}

const Footer = styled.div`
  text-align: left;
  margin-top: 30px;
  padding: 0 15px 15px 15px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const A = styled.a`
  color: ${primaryTeal};
  font-size: ${smallerFontSize};
  &:hover {
    cursor: pointer;
    color: ${secondaryTealLight};
  }
`;

const P = styled.p`
  color: ${primaryWhite};
  font-size: ${smallerFontSize};
  &:last-child {
    margin-bottom: 5px;
  }
`;

const CopyRightA = styled(A)`
  margin-left: 0;
  margin-right: 0;
`;
