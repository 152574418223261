import styled from "@emotion/styled";
import {fontSans, MediaQueryMobile, primaryWhite} from "../styles/variables";

export const MyMobilityH2 = styled.h2`
  font-family: ${fontSans};
  font-size: 30px;
  color: ${primaryWhite};
  font-weight: 600;

  ${MediaQueryMobile} {
    font-size: 24px;
  }
`;
