export function getUrlParameter(
  name: string,
  location: string = window.location.search
) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
