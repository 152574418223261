import styled from "@emotion/styled";
import { errorColor, smallerFontSize } from "../../styles/variables";

export const SSOLoginHint = styled.p`
  font-size: ${smallerFontSize};
  margin-top: 10px;
`;

export const SSOErrorHint = styled(SSOLoginHint)`
  color: ${errorColor};
`;
