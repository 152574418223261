import React from "react";
import { useTranslation } from "react-i18next";
import { Group } from "./Group";
import lockImgSrc from "../../images/lock.png";
import styled from "@emotion/styled";
import {MediaQueryMobile} from "../../styles/variables";

export function Headline() {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <Img src={lockImgSrc} alt="myMobility" />
        <h2>{t("Complete Secure Login")}</h2>
      </Header>
      <Group>
        <p>
          {t(
            "We will send you a one-time access code to complete your login. Choose below how you would like to receive the code"
          )}
        </p>
      </Group>
    </>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 15px;

    ${MediaQueryMobile} {
      font-size: 24px;
    }
  }
`;

const Img = styled.img`
  width: 56px;
  margin-bottom: 10px;
`;
