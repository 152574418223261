import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppConfig } from "../hooks/appConfig";
import {
  secondaryGrey2,
  primaryBlack,
  smallerFontSize,
} from "../styles/variables";
import { setLocalStorage } from "../utils/storage";

export function LanguageDropdown() {
  const { cultures } = useAppConfig();
  const { i18n } = useTranslation();

  const nonCurrentCultures = cultures.filter(
    ({ value }) => value !== i18n.language
  );

  return (
    <LanguageDropdownContainer>
      {nonCurrentCultures.map((c) => (
        <Language key={c.value} {...c} />
      ))}
    </LanguageDropdownContainer>
  );
}

function Language(props: { value: string; text: string }) {
  const { i18n } = useTranslation();

  async function changeLanguage() {
    await i18n.changeLanguage(props.value);
    setLocalStorage("culture", props.value);
  }

  return <A onClick={changeLanguage}>{props.text}</A>;
}

const A = styled.a`
  padding: 2px 18px;
  &:hover {
    background: ${secondaryGrey2};
  }
`;
export const LanguageDiv = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
`;
const LanguageDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -25%;
  background: white;
  cursor: pointer;
  color: ${primaryBlack};
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  font-size: ${smallerFontSize};
`;
