import styled from "@emotion/styled";
import _ from "lodash";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryFormButton } from "../../components/Buttons";
import { CenteredForm } from "../../components/CenteredForm";
import { H2 } from "../../components/H2";
import { useReactRouterRedirect } from "../../hooks/useReactRouterRedirect";
import { useUsername } from "../../hooks/username";
import { Layout } from "../../Layout";
import { sendLoginAndAssociateRequest } from "../../requests";
import { redirectTo } from "../../utils/redirectHelper";
import { ForgetPasswordLink } from "../Login/ForgetPasswordLink";
import lockImgSrc from "../../images/lock.png";
import { MessagePopup } from "../../components/MessagePopup";
import { useAppConfig } from "../../hooks/appConfig";
import { getLocalStorage } from "../../utils/storage";
import { brandingConfig } from "../../branding/config";
import { TextFieldFloating } from "../../components/TextFieldFloating";

export function ConnectAccount() {
  const { t } = useTranslation();
  const [usernameOrCorpEmail, setUsernameOrCorpEmail] = useUsername();
  const [returnUrl, setReturnUrl] = useState("");
  const [corpEmail, setCorpEmail] = useState("");
  const [showConnectSuccessPopup, setShowConnectSuccessPopup] = useState(false);
  const [showNotAllowedConnectPopup, setShowNotAllowedConnectPopup] = useState(
    false
  );
  const validate = useValidate();
  const reactRouterRedirect = useReactRouterRedirect();
  const { myPwCEntryPoint } = useAppConfig();

  return (
    <Layout height={640}>
      {showConnectSuccessPopup && (
        <MessagePopup
          title={t("Corporate Login connected successfully")}
          content={t(
            "Your connected corporate email is: <strong>{corpEmail}</strong>. You can use your corporate email to login the next time you access myMobility.",
            { corpEmail: corpEmail }
          )}
          returnUrl={returnUrl}
          buttonText={t("Thanks, I got it")}
        />
      )}
      {showNotAllowedConnectPopup && (
        <MessagePopup
          title={t("Can not Connect to Corporate Login")}
          content={t(
            "Hi <strong>{username}</strong> <br><br> We are unable to set up your myMobility Corporate Login as <strong>{corpEmail}</strong> is not yet connected to the {company} system. We will let you know in the future if we can connect your account using Corporate Login. In the meantime, you can continue to login using your existing username and password.",
            {
              username: usernameOrCorpEmail,
              corpEmail: "@" + corpEmail.split("@")[1],
              company: brandingConfig.company,
            }
          )}
          returnUrl={myPwCEntryPoint}
          buttonText={t("Login via Username and Password")}
          failed={true}
        />
      )}
      <Formik
        initialValues={{ username: "", password: "" }}
        validate={validate}
        validateOnChange={false}
        onSubmit={async (
          { username, password },
          { setFieldError, setSubmitting }
        ) => {
          username = _.trim(username);
          try {
            const culture = getLocalStorage("culture") || "en-US";
            const response = await sendLoginAndAssociateRequest(
              username,
              password,
              "",
              culture
            );
            setSubmitting(false);
            if (response.data.authenticated) {
              setReturnUrl(response.data.returnUrl);
              setCorpEmail(response.data.corpEmail);
              setShowConnectSuccessPopup(true);
            } else if (response.status === 200) {
              setUsernameOrCorpEmail(username);
              reactRouterRedirect("/delivery-and-associate");
            }
          } catch (e) {
            if (e.response.status === 423) {
              redirectTo("/forget-password?isLocked=true");
            } else if (e.response.status === 403) {
              setFieldError(
                "username",
                t(
                  "Your account is currently inactive. Please contact your {company} contact to reactivate your account.",
                  { company: brandingConfig.company }
                )
              );
            } else if (e.response.status === 409) {
              setFieldError(
                "username",
                t(
                  "We detect that the corporate email you are using has been registered as an account’s username. Please enter the email in the username field and login with your password."
                )
              );
            } else if (e.response.status === 400) {
              setCorpEmail(e.response.data.corpEmail);
              setUsernameOrCorpEmail(username);
              setShowNotAllowedConnectPopup(true);
            } else {
              setFieldError(
                "username",
                t("Your username or password is incorrect.")
              );
              setFieldError(
                "password",
                t("Your username or password is incorrect.")
              );
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <CenteredForm>
            <Header>
              <Img src={lockImgSrc} alt="myMobility" />
              <H2>{t("Connect Your Account")}</H2>
            </Header>

            <HintSection>
              <P>
                {t(
                  "We were not able to successfully complete Corporate Login. Please login with your myMobility username and password to connect your Corporate Login for a simplified login process."
                )}
              </P>
            </HintSection>

            <TextFieldFloating name="username">
              {t("myMobility Username")}
            </TextFieldFloating>
            <TextFieldFloating name="password" autoFocus type="password">
              {t("myMobility Password")}
            </TextFieldFloating>
            <PrimaryFormButton
              data-testid="login-and-enable-corporate-login"
              type="submit"
              disabled={isSubmitting}
            >
              {t("Login and Enable Corporate Login")}
            </PrimaryFormButton>
            <ForgetPasswordLink />
          </CenteredForm>
        )}
      </Formik>
    </Layout>
  );
}

function useValidate() {
  const { t } = useTranslation();

  return function ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    const errors: { [key: string]: string } = {};
    if (!username || !username.trim()) {
      errors["username"] = t("{name} is required", { name: "Username" });
    }
    if (!password) {
      errors["password"] = t("{field} is required", { field: "Password" });
    }
    return errors;
  };
}

const HintSection = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  order: 1;
`;

const P = styled.p`
  margin: 6px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

const Img = styled.img`
  width: 20px;
  margin-top: -5px;
  margin-right: 10px;
`;
