import React, { useState } from "react";

export const useCountDown = function (startNumber: number) {
  const [onCountingDown, setOnCountingDown] = useState(false);
  const [countingDownNumber, setCountingDownNumber] = useState(startNumber);
  React.useEffect(() => {
    if (!onCountingDown) {
      return;
    }
    countingDownNumber > 0 &&
      setTimeout(() => setCountingDownNumber(countingDownNumber - 1), 1000);
    if (countingDownNumber <= 0) {
      setOnCountingDown(false);
      setCountingDownNumber(startNumber);
    }
  }, [onCountingDown, countingDownNumber, startNumber]);
  return [onCountingDown, setOnCountingDown, countingDownNumber] as const;
};
