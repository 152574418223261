import { Formik, useFormikContext } from "formik";
import _ from "lodash";
import React, { useState, useRef, SetStateAction, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import {
  PrimaryFormButton,
  SecondaryFormButton,
} from "../../components/Buttons";
import { useReactRouterRedirect } from "../../hooks/useReactRouterRedirect";
import { useUsername } from "../../hooks/username";
import { getHasSSOAssociated, sendLoginRequest } from "../../requests";
import { redirectTo } from "../../utils/redirectHelper";
import { verifyRedirectUrl } from "../../utils/verifyUrlHelper";
import { getUrlParameter } from "../../utils/urlHelper";
import { ForgetPasswordLink } from "./ForgetPasswordLink";
import { LoginLayout } from "./LoginLayout";
import { Separator } from "./Separator";
import { SSOErrorHint } from "./SSOLoginHint";
import { useValidation } from "./useValidation";
import { getLocalStorage } from "../../utils/storage";
import { useHasClientAssociation } from "../../hooks/hasClientAssociation";
import { brandingConfig } from "../../branding/config";
import { useAppConfig } from "../../hooks/appConfig";
import { TextFieldFloating } from "../../components/TextFieldFloating";
import styled from "@emotion/styled";
import { Headline } from "./Headline";

type Ref<T> = {
  current?: Dispatch<SetStateAction<T>>;
};

export function LoginStepNormalWithSSO() {
  const reactRouterRedirect = useReactRouterRedirect();
  const [usernameOrCorpEmail, setUsernameOrCorpEmail] = useUsername();
  const { t } = useTranslation();
  const { validateUsername, validatePassword } = useValidation();
  const [hasClientAssociation] = useHasClientAssociation();
  const updateSSOLoginErrorHandler: Ref<string> = useRef();
  const validate = (values: {
    usernameOrCorpEmail: string;
    password: string;
  }) => {
    const errors: { [key: string]: string } = {};
    validateUsername(
      values.usernameOrCorpEmail,
      errors,
      updateSSOLoginErrorHandler.current
    );
    validatePassword(
      values.password,
      errors,
      updateSSOLoginErrorHandler.current
    );
    return errors;
  };
  const { redirectWhiteList, redirectErrorUrl } = useAppConfig();

  return (
    <Formik
      initialValues={{ usernameOrCorpEmail, password: "" }}
      validate={validate}
      onSubmit={async (
        { usernameOrCorpEmail, password },
        { setSubmitting, setFieldError }
      ) => {
        usernameOrCorpEmail = _.trim(usernameOrCorpEmail);
        try {
          const culture = getLocalStorage("culture") || "en-US";
          const response = await sendLoginRequest(
            usernameOrCorpEmail,
            password,
            getUrlParameter("ReturnUrl"),
            culture
          );
          setSubmitting(false);
          if (response.data.authenticated) {
            const verifyUrl = verifyRedirectUrl(
              response.data.returnUrl,
              redirectWhiteList,
              redirectErrorUrl
            );
            redirectTo(verifyUrl);
          } else if (response.status === 200) {
            setUsernameOrCorpEmail(usernameOrCorpEmail);
            reactRouterRedirect("/delivery");
          }
        } catch (e) {
          if (e.response.status === 423) {
            redirectTo("/forget-password?isLocked=true");
          } else if (e.response.status === 403) {
            setFieldError(
              "usernameOrCorpEmail",
              t(
                "Your account is currently inactive. Please contact your {company} contact to reactivate your account.",
                { company: brandingConfig.company }
              )
            );
          } else {
            if (
              updateSSOLoginErrorHandler &&
              updateSSOLoginErrorHandler.current
            ) {
              updateSSOLoginErrorHandler.current("");
            }
            setFieldError(
              "usernameOrCorpEmail",
              t("Your username or password is incorrect.")
            );
            setFieldError(
              "password",
              t("Your username or password is incorrect.")
            );
          }
        }
      }}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <LoginLayout>
          <Headline />
          <TextFieldFloating name="usernameOrCorpEmail" width={400}>
            {t("Username")}
          </TextFieldFloating>
          <TextFieldFloating
            name="password"
            autoFocus
            type="password"
            width={400}
          >
            {t("Password")}
          </TextFieldFloating>
          <LoginButton
            data-testid="button-login-via-username-and-psw"
            type="submit"
            disabled={isSubmitting}
          >
            {t("Next")}
          </LoginButton>
          <ForgetPasswordLink />
          {hasClientAssociation && <Separator>{t("or")}</Separator>}
          {hasClientAssociation && (
            <SsoSubmitButton
              updateErrorHandlerRef={updateSSOLoginErrorHandler}
            />
          )}
        </LoginLayout>
      )}
    </Formik>
  );
}

function SsoSubmitButton(props: { updateErrorHandlerRef: Ref<string> }) {
  const [errorMsg, setErrorMsg] = useState("");
  props.updateErrorHandlerRef.current = setErrorMsg;
  const { values, setFieldError, errors } = useFormikContext<{
    usernameOrCorpEmail: string;
    password: string;
  }>();
  const { t } = useTranslation();
  const usernameOrCorpEmail = _.trim(values.usernameOrCorpEmail);

  const submitHandler = async () => {
    const hasSSOAssociated = (await getHasSSOAssociated(usernameOrCorpEmail))
      .data;

    if (!hasSSOAssociated) {
      const translation = t(
        "Please enter your corporate email to sign in using Corporate Login or login using your myMobility username and password."
      );
      setErrorMsg(translation);
      setFieldError("usernameOrCorpEmail", "");
      setFieldError("password", "");
      return;
    }

    await loginViaSSO(usernameOrCorpEmail);
  };
  Object.keys(errors)
    .map((k) => errors[k as keyof typeof errors])
    .every((x) => !x);
  const showError = !!errorMsg;

  return (
    <>
      <SecondaryFormButton
        type="button"
        onClick={submitHandler}
        data-testid="button-corporate-login"
      >
        {t("Corporate Login")}
      </SecondaryFormButton>
      {showError && <SSOErrorHint>{errorMsg}</SSOErrorHint>}
    </>
  );
}

async function loginViaSSO(usernameOrCorpEmail: string) {
  const culture = getLocalStorage("culture") || "en-US";
  redirectTo(
    `/ExternalIdentity/Login?usernameOrCorpEmail=${usernameOrCorpEmail}&returnUrl=${encodeURIComponent(
      getUrlParameter("ReturnUrl")
    )}&culture=${culture}`
  );
}

const LoginButton = styled(PrimaryFormButton)`
  display: block;
  margin: 4px auto 24px;
  width: 244px;
  height: 48px;
`;
