import styled from "@emotion/styled";
import { BlockLink, DisabledBlockLink } from "../../components/BlockLink";

export const BlockLinkContainer = styled(BlockLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DisabledBlockLinkContainer = styled(DisabledBlockLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Group = styled.div`
  margin: 24px 0;
  font-size: 16px;
  line-height: 24px;

  p {
    font-size: 16px;
  }

  & .MuiRadio-root,
  .MuiCheckbox-root {
    margin: 0 15px 0 -9px;
  }
`;
