import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { Culture } from "./requests";
import parse from "./utils/poparser";
import { getLocalStorage } from "./utils/storage";

export async function setupI18n(cultures: Culture[]) {
  const cultureValues = cultures.map(({ value }) => value);
  return await i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: "/Translation/Get?culture={lng}",
        parse: (data: any) => {
          const parsed: any = parse(data);
          const defaultContext = parsed.translations[""];
          const result: any = {};

          Object.keys(defaultContext).forEach(
            (key) => (result[key] = defaultContext[key].msgstr[0])
          );

          return result;
        },
      },
      lng: getLocalStorage("culture") || "en-US",
      load: "currentOnly",
      keySeparator: false,
      nsSeparator: false,
      fallbackLng: false,
      whitelist: cultureValues,
      // debug: env.debug,
      interpolation: {
        prefix: "{",
        suffix: "}",
        escapeValue: false,
      },
      supportedLngs: cultureValues,
    });
}
