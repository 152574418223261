import React from "react";
import logoWhite from "../images/vialto-logo-white.svg";
import styled from "@emotion/styled";
import {zIndexHigh, primaryBlack, MediaQueryMobile} from "../styles/variables";
import { redirectTo } from "../utils/redirectHelper";
import { useAppConfig } from "../hooks/appConfig";
import { HelpOutline } from "@material-ui/icons";
import { BlockLink } from "../components/BlockLink";
import { useLocation } from "react-router-dom";
import { LanguagePicker } from "./LanguagePicker";

function HelpLink() {
  return (
    <HelpBlockLink to={`/journey/about`}>
      <HelpIcon />
    </HelpBlockLink>
  );
}

export function Header({
  showLanguagePicker = true,
}: {
  showLanguagePicker?: boolean;
}) {
  const { myPwCEntryPoint } = useAppConfig();
  const { pathname } = useLocation();
  return (
    <Div showLanguagePicker={showLanguagePicker}>
      <Image
        showLanguagePicker={showLanguagePicker}
        src={logoWhite}
        alt="myMobility logo"
        onClick={async () => {
          redirectTo(myPwCEntryPoint);
        }}
        data-testid="mymobility-logo"
      />
      {showLanguagePicker && <LanguagePicker />}
    </Div>
  );
}

const Div = styled.div<{ showLanguagePicker: boolean }>`
  background: ${primaryBlack};
  width: 100%;
  padding: ${(props) =>
    props.showLanguagePicker ? "32px 48px 0 42px" : "32px 0 0"};
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${zIndexHigh};
  position: relative;
  box-sizing: border-box;
  
  ${MediaQueryMobile} {
    padding: 0;
    margin-bottom: 32px;
  }
`;

const Image = styled.img<{ showLanguagePicker: boolean }>`
  width: ${(props) => (props.showLanguagePicker ? "320px" : "100%")};
  height: 38px;
  cursor: pointer;
  
  ${MediaQueryMobile} {
    width: 70%;
    height: auto;
  }
`;

const HelpBlockLink = styled(BlockLink)`
  height: 20px;
  position: absolute;
  right: 10px;
`;

const HelpIcon = styled(HelpOutline)`
  height: auto !important;
  font-size: 20px !important;
  margin-left: 4px;
  color: white;
  cursor: pointer;
`;
