import React from "react";
import { redirectTo } from "../../utils/redirectHelper";
import { getUrlParameter } from "../../utils/urlHelper";
import { useMount } from "../../hooks/useMount";

export function UpdateExternalIdentity() {
  useMount(async () => {
    const clientId = getUrlParameter("clientId");
    const returnUrl = getUrlParameter("returnUrl");
    redirectTo(
      `/ExternalIdentity/Update?clientId=${clientId}&returnUrl=${returnUrl}`
    );
  });
  return <div />;
}
