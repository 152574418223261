import styled from "@emotion/styled";
import { BlockLink, DisabledBlockLink } from "../../../components/BlockLink";

export const BlockLinkContainer = styled(BlockLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DisabledBlockLinkContainer = styled(DisabledBlockLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Group = styled.div`
  margin: 20px 0;
`;
