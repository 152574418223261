import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { getFingerprint } from "./utils/fingerprint";
import UAParser from "ua-parser-js";

const parser = new UAParser();

export async function getHasSSOAssociated(usernameOrCorpEmail: string) {
  return await axios.post<boolean>("/ClientSSOAssociations/HasSSOAssociated", {
    corpEmail: usernameOrCorpEmail,
  });
}

export async function isFromSameAccount(corpEmail: string, accountId: string) {
  return await axios.post<boolean>("/ClientSSOAssociations/IsFromSameAccount", {
    accountId,
    corpEmail,
  });
}

export async function sendLoginRequest(
  usernameOrCorpEmail: string,
  password: string,
  returnUrl: string,
  culture: string
) {
  return await axios.post<{
    authenticated: boolean;
    returnUrl: string;
  }>("/Session/Login", {
    usernameOrCorpEmail,
    password: password.trim(),
    fingerprint: await getFingerprint(),
    returnUrl: returnUrl,
    culture: culture,
  });
}

export async function sendLoginAndAssociateRequest(
  usernameOrCorpEmail: string,
  password: string,
  returnUrl: string,
  culture: string | null
) {
  return await axios.post<{
    authenticated: boolean;
    returnUrl: string;
    corpEmail: string;
  }>("/Session/LoginAndAssociate", {
    usernameOrCorpEmail,
    password: password.trim(),
    fingerprint: await getFingerprint(),
    returnUrl: returnUrl,
    culture: culture,
  });
}

export type Senders = {
  primaryEmail: string;
  secondaryEmail: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
};

export async function getSenders(usernameOrCorpEmail: string) {
  return await axios.post<Senders>("/PinCode/GetSendersByUsername", {
    usernameOrCorpEmail,
  });
}

export async function sendLoginPinCode(
  username: string,
  sender?: string,
  sendThrough?: string
) {
  return await axios.post("/Session/SendPinCode", {
    credential: {
      username,
    },
    sender,
    sendThrough,
    template: "",
  });
}

export async function verifyPinCode(
  pinCode: string,
  rememberMe: boolean,
  culture: string | null
) {
  return await axios.post<{ returnUrl: string }>("/Session/VerifyPinCode", {
    pinCode,
    rememberMe,
    fingerprint: await getFingerprint(),
    browserName: parser.getBrowser().name,
    OSPlatformName: parser.getOS().name,
    culture: culture,
  });
}

export async function verifyPinCodeAndAssociate(
  pinCode: string,
  rememberMe: boolean,
  culture: string | null
) {
  return await axios.post<{ returnUrl: string; corpEmail: string }>(
    "/Session/VerifyPinCodeAndAssociate",
    {
      pinCode,
      rememberMe,
      fingerprint: await getFingerprint(),
      browserName: parser.getBrowser().name,
      OSPlatformName: parser.getOS().name,
      culture: culture,
    }
  );
}

export type Culture = {
  value: string;
  text: string;
};

export type CopyrightLinks = {
  webSupportContactEmail: string;
  copyrightLink: string;
  securityLink: string;
  privacyLink: string;
  cookieInfoLink: string;
};

export type AppConfig = CopyrightLinks & {
  cultures: Culture[];
  myPwCEntryPoint: string;
  myMobilityHQWelcomePageUrl: string;
  myMobilityHQIntroduction: string;
  resetPasswordInterval: number;
  posthogApiKey: string;
  posthogApiHostUrl: string;
  applicationInsightsConnectionString: string;
  redirectWhiteList: string;
  redirectErrorUrl: string;
};

export type Template = {
  templates: {
    Body: string;
  };
};

export async function getAppConfig() {
  return await axios.get<AppConfig>("/AppConfig/Get");
}

export type EmailDto = {
  primaryEmail: string;
};

export async function getEmailByTicket(ticketId: string) {
  return await axios.get<EmailDto>(
    `/Registration/GetEmailByTicket/${ticketId}`
  );
}

export async function registerUser(
  inviteTicket: string,
  username: string,
  password: string,
  cultureCode: string
) {
  return await axios.post<{ returnUrl: string }>(
    `/Registration/Register/${inviteTicket}/Credential/${cultureCode}`,
    {
      username,
      password: password?.trim(),
    }
  );
}

export async function registerProxy(o: {
  inviteTicket: string;
  username: string;
  password: string;
  accessCode: string;
  email: string;
  cultureCode: string;
}) {
  return await axios.post<{ returnUrl: string }>(
    `/Registration/RegisterProxy/${o.inviteTicket}/Credential/${o.cultureCode}`,
    {
      username: o.username,
      password: o.password?.trim(),
      email: o.email,
      accessCode: o.accessCode,
    }
  );
}

export async function checkUsernameUnique(
  username: string,
  inviteTicket: string
) {
  return await axios.post<
    boolean,
    AxiosResponse<boolean>,
    { username: string; inviteTicket: string }
  >(
    "/Registration/UsernameUnique",
    {
      username,
      inviteTicket,
    },
    {
      headers: {
        fingerprint: await getFingerprint(),
      },
    }
  );
}

export async function getDeviceLockedStatus() {
  return await axios.post<boolean>("/Registration/GetDeviceLockedStatus", {
    type: "UserNameUnique",
    fingerprint: await getFingerprint(),
  });
}

export async function validatePasswordAsync(
  username: string,
  password: string
) {
  return await axios.post<"InvalidPasswordFormat" | "PasswordIsTooCommon">(
    "/Registration/ValidatePassword",
    {
      username,
      password,
    }
  );
}

export async function verifyInviteTicket(inviteTicket: string) {
  return await axios.put<boolean>(
    `/Registration/VerifyInviteTicket/${inviteTicket}`
  );
}

export async function verifyProxyInviteTicket(
  inviteTicket: string,
  email: string,
  accessCode: string
) {
  return await axios.put<boolean>(
    `/Registration/VerifyProxyInviteTicket/${inviteTicket}`,
    {
      email,
      accessCode,
    }
  );
}

export async function sendResetPasswordEmail(
  emailAddress: string,
  sendThrough: string,
  username: string,
  applicationName: string
) {
  return await axios.post(
    "/ResetPassword/SendResetPasswordEmail",
    {
      emailAddress,
      sendThrough,
      username,
      applicationName,
    },
    {
      headers: {
        fingerprint: await getFingerprint(),
      },
    }
  );
}

export async function SendResetPasswordRequest(
  inviteTicket: string,
  username: string,
  password: string,
  pinCode: string,
  culture: string
) {
  return await axios.put("/Credential/ResetByPinCode", {
    inviteTicket,
    username,
    password: password?.trim(),
    pinCode,
    culture,
  });
}

let lastGetTemplatesRequestIsOngoing: CancelTokenSource | null;
export async function getTemplates(name: string, culture: string) {
  if (lastGetTemplatesRequestIsOngoing) {
    lastGetTemplatesRequestIsOngoing.cancel();
    lastGetTemplatesRequestIsOngoing = null;
  }
  lastGetTemplatesRequestIsOngoing = axios.CancelToken.source();
  const result = await axios.get<Template>(
    `/Templates?name=${name}&culture=${culture}`,
    {
      cancelToken: lastGetTemplatesRequestIsOngoing.token,
    }
  );
  lastGetTemplatesRequestIsOngoing = null;
  return result;
}
