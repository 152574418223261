import React from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "../../components/Radio";
import { Group } from "./Group";
import { TextOrCall } from "../../hooks/senders";
import { P } from "./common";
import { Senders } from "../../requests";

export function SelectTextOrCall(props: { senders: Senders }) {
  const { t } = useTranslation();
  const isUSNumber = props.senders.primaryPhoneNumber.startsWith('+1') || (props.senders.secondaryPhoneNumber && props.senders.secondaryPhoneNumber.startsWith('+1'));

  return (
    <Group>
      <P>{t("How should we send you the access code?")}</P>
      {isUSNumber ? 
        (<P>{t("While we upgrade the SMS system, SMS messages for PIN codes are unavailable, you must use voice or email to receive PIN code.")}</P>): 
      (<Radio name="textOrCall" value={TextOrCall.message}>
        {t("Text Message (only for mobile phones)")}
      </Radio>)}
      <Radio name="textOrCall" value={TextOrCall.voiceCall}>
        {t("Voice Call")}
      </Radio>
    </Group>
  );
}
