import styled from "@emotion/styled";

export const Group = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;

  p {
    font-size: 16px;
  }

  & .MuiRadio-root {
    padding: 0 24px 0 0;
  }
`;
