import { Formik } from "formik";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHasSSOAssociated } from "../../requests";
import { LoginLayout } from "./LoginLayout";
import { LoginSteps } from "../../constants";
import { TextField } from "../../components/TextField";
import {
  PrimaryFormButton,
  SecondaryFormButton,
} from "../../components/Buttons";
import { useValidation } from "./useValidation";
import { useUsername } from "../../hooks/username";
import { ForgetPasswordLink } from "./ForgetPasswordLink";
import { Separator } from "./Separator";
import { SSOErrorHint, SSOLoginHint } from "./SSOLoginHint";
import { getUrlParameter } from "../../utils/urlHelper";
import { redirectTo } from "../../utils/redirectHelper";
import styled from "@emotion/styled";
import { getLocalStorage } from "../../utils/storage";
import _ from "lodash";
import { brandingConfig } from "../../branding/config";
import { TextFieldFloating } from "../../components/TextFieldFloating";
import lockImgSrc from "../../images/lock.png";
import { MyMobilityH2 } from "../../components/MyMobilityH2";
import { HeadlineContainer } from "./Headline";

export function LoginStepSSO(props: {
  setStep: Dispatch<SetStateAction<LoginSteps>>;
  corpEmail: string;
  isInactive: boolean;
}) {
  const { t } = useTranslation();
  const [usernameOrCorpEmail, setUsernameOrCorpEmail] = useUsername();
  const initCorpLoginError = props.isInactive
    ? t(
        "Your account is currently inactive. Please contact your {company} contact to reactivate your account.",
        { company: brandingConfig.company }
      )
    : "";
  const [corpLoginError, setCorpLoginError] = useState(initCorpLoginError);
  const { validateUsername } = useValidation();
  const validate = (values: { usernameOrCorpEmail: string }) => {
    const errors: { [key: string]: string } = {};
    validateUsername(values.usernameOrCorpEmail, errors);
    return errors;
  };
  const goToNormalLoginWithSSO = () => {
    props.setStep(LoginSteps.NormalLoginWithSSO);
  };

  return (
    <Formik
      initialValues={{
        usernameOrCorpEmail: usernameOrCorpEmail || props.corpEmail,
      }}
      validate={validate}
      onSubmit={async ({ usernameOrCorpEmail }, { setSubmitting }) => {
        usernameOrCorpEmail = _.trim(usernameOrCorpEmail);
        setSubmitting(false);
        setUsernameOrCorpEmail(usernameOrCorpEmail);
        const hasSSOAssociated = (
          await getHasSSOAssociated(usernameOrCorpEmail)
        ).data;
        if (!hasSSOAssociated) {
          setCorpLoginError(
            t(
              "Please enter your corporate email to sign in using Corporate Login or login using your myMobility username and password."
            )
          );
          return;
        }
        const culture = getLocalStorage("culture") || "en-US";
        setCorpLoginError("");
        redirectTo(
          `/ExternalIdentity/Login?usernameOrCorpEmail=${usernameOrCorpEmail}&returnUrl=${encodeURIComponent(
            getUrlParameter("ReturnUrl")
          )}&culture=${culture}`
        );
      }}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <LoginLayout>
          <HeadlineContainer>
            <Img src={lockImgSrc} alt="myMobility" />
            <MyMobilityH2>myMobility Secure Login</MyMobilityH2>
          </HeadlineContainer>
          <TextFieldFloating name="usernameOrCorpEmail" width={545}>
            {t("Corporate Email")}
          </TextFieldFloating>
          <StyledPrimaryFormButton
            type="submit"
            disabled={isSubmitting}
            data-testid="button-corporate-login"
          >
            {t("Corporate Login")}
          </StyledPrimaryFormButton>
          <SSOErrorHint>{corpLoginError}</SSOErrorHint>
          {!corpLoginError && (
            <SSOLoginHint>
              {t(
                "Make login easy - corporate login allows you to login using your corporate credentials so you do not need to remember your myMobility username and password."
              )}
            </SSOLoginHint>
          )}
          <Separator>{t("or")}</Separator>
          <StyledSecondaryFormButton
            type="button"
            onClick={goToNormalLoginWithSSO}
            data-testid="button-login-via-username-and-psw"
          >
            {t("Login via Username and Password")}
          </StyledSecondaryFormButton>
          <ForgetPasswordLink />
        </LoginLayout>
      )}
    </Formik>
  );
}

const StyledPrimaryFormButton = styled(PrimaryFormButton)`
  width: 304px;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledSecondaryFormButton = styled(SecondaryFormButton)`
  width: 304px;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Img = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 10px;
`;
