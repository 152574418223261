import i18n from "i18next";
import React, { createContext, ReactNode } from "react";
import { setupI18n } from "../setupI18n";
import { useMount } from "./useMount";
import { useAppConfig } from "./appConfig";
import { setLocalStorage } from "../utils/storage";
import { getUrlParameter } from "../utils/urlHelper";

export const i18nInitializerContext = createContext(null);

async function updateCulturePreference() {
  const redirectUrl = decodeURIComponent(
    decodeURIComponent(window.location.href)?.split("redirect_uri=")[1]
  );
  const initialCulture = getUrlParameter(
    "culture",
    getUrlParameter("ReturnUrl")
  );

  if (initialCulture) {
    await i18n.changeLanguage(initialCulture);
    setLocalStorage("culture", initialCulture);
  }
}

export function I18nInitializerProvider(props: { children: ReactNode }) {
  const { cultures } = useAppConfig();

  useMount(async () => {
    await setupI18n(cultures);
    await updateCulturePreference();
  });

  return (
    <i18nInitializerContext.Provider value={null}>
      {props.children}
    </i18nInitializerContext.Provider>
  );
}
