import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { primaryTeal, secondaryTealLight } from "../styles/variables";

export const BlockLink = styled(Link)`
  display: block;
  color: ${primaryTeal};
  &:hover {
    color: ${secondaryTealLight};
  }
`;

export const DisabledBlockLink = styled.a`
  display: block;
  color: ${primaryTeal};
`;
