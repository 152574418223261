import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { BlockLinkContainer, DisabledBlockLinkContainer } from "./components";
import styled from "@emotion/styled";
import { primaryTeal, secondaryTealLight } from "../../styles/variables";

export function GoBack(props: {
  hasExceeded: boolean;
  associateExistingAccount?: boolean;
}) {
  return (
    <GoBackContainer>
      {props.hasExceeded ? (
        <DisabledLink />
      ) : (
        <ActiveLink associateExistingAccount={props.associateExistingAccount} />
      )}
    </GoBackContainer>
  );
}

function ActiveLink(props: { associateExistingAccount?: boolean }) {
  const { t } = useTranslation();

  return (
    <BlockLinkContainer
      to={
        props.associateExistingAccount ? "/delivery-and-associate" : "/delivery"
      }
    >
      <ChevronLeft />
      <span>{t("Select a different delivery method")}</span>
    </BlockLinkContainer>
  );
}

function DisabledLink() {
  const { t } = useTranslation();

  return (
    <DisabledBlockLinkContainer>
      <ChevronLeft />
      <span>{t("Select a different delivery method")}</span>
    </DisabledBlockLinkContainer>
  );
}

export const GoBackContainer = styled.div`
  width: 100%;

  svg,
  span {
    color: ${primaryTeal};

    &:hover {
      color: ${secondaryTealLight};
    }
  }
`;
