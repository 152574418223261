import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { AppConfigProvider } from "./hooks/appConfig";
import { SenderValuesProvider } from "./hooks/senders";
import { UsernameProvider } from "./hooks/username";
import { ConnectAccount } from "./pages/ConnectAccount";
import { Delivery } from "./pages/Delivery";
import { DisconnectExternalIdentity } from "./pages/ExternalIdentity/DisconnectExternalIdentity";
import { ResetPassword } from "./pages/ForgetPassword/ResetPassword";
import { ForgetPassword } from "./pages/ForgetPassword/ForgetPassword";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { Registration } from "./pages/Registration";
import { ExternalIdentity } from "./pages/ExternalIdentity/ExternalIdentity";
import { Verify } from "./pages/Verify";
import { PinCodeSendersProvider } from "./hooks/pinCodeSenders";
import { BackToLogin } from "./pages/ForgetPassword/BackToLogin";
import { PinCodeExpiredSecondsProvider } from "./hooks/pinCodeExpiredSeconds";
import { ShowErrorProvider } from "./hooks/showError";
import { UpdateExternalIdentity } from "./pages/ExternalIdentity/UpdateExternalIdentity";
import { HasClientAssociationProvider } from "./hooks/hasClientAssociation";
import { AnnouncementBannerProvider } from "./hooks/useAnnouncementBanner";
import { I18nInitializerProvider } from "./hooks/i18nInitializer";
import { Analytics } from "./components/Analytics";
import { PosthogAnalytics } from "./components/PostHogAnaytics";
import JourneyRouter from "./JourneyRouter";
import { capturePageView } from "./utils/posthog";

export function App() {
  return (
    <BrowserRouter>
      <Suspense fallback="">
        <Root />
      </Suspense>
    </BrowserRouter>
  );
}

export function Root() {
  const currentLocation = useLocation();

  useEffect(() => {
    capturePageView();
  }, [currentLocation]);

  return (
    <AppConfigProvider>
      <Analytics />
      <PosthogAnalytics />
      <I18nInitializerProvider>
        <UsernameProvider>
          <AnnouncementBannerProvider>
            <SenderValuesProvider>
              <PinCodeSendersProvider>
                <PinCodeExpiredSecondsProvider>
                  <ShowErrorProvider>
                    <HasClientAssociationProvider>
                      <Switch>
                        <Route path="/journey" component={JourneyRouter} />
                        <Route path="/login">
                          <Login />
                        </Route>
                        <Route path="/logout">
                          <Logout />
                        </Route>
                        <Route path="/delivery">
                          <Delivery />
                        </Route>
                        <Route path="/delivery-and-associate">
                          <Delivery associateExistingAccount />
                        </Route>
                        <Route path="/verify">
                          <Verify />
                        </Route>
                        <Route path="/verify-and-associate">
                          <Verify associateExistingAccount />
                        </Route>
                        <Route path="/forget-password">
                          <ForgetPassword />
                        </Route>
                        <Route path="/forget-password-back-to-login">
                          <BackToLogin />
                        </Route>
                        <Route path="/reset-password">
                          <ResetPassword />
                        </Route>
                        <Route path="/registration">
                          <Registration />
                        </Route>
                        <Route path="/external-identity">
                          <ExternalIdentity />
                        </Route>
                        <Route path="/connect-account">
                          <ConnectAccount />
                        </Route>
                        <Route path="/disconnect-external-identity">
                          <DisconnectExternalIdentity />
                        </Route>
                        <Route path="/update-external-identity">
                          <UpdateExternalIdentity />
                        </Route>
                        <Route path="/">
                          <Login />
                        </Route>
                      </Switch>
                    </HasClientAssociationProvider>
                  </ShowErrorProvider>
                </PinCodeExpiredSecondsProvider>
              </PinCodeSendersProvider>
            </SenderValuesProvider>
          </AnnouncementBannerProvider>
        </UsernameProvider>
      </I18nInitializerProvider>
    </AppConfigProvider>
  );
}
