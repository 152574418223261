import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const UsernameContext = createContext<Ctx<string>>(["", () => {}]);

export function useUsername(): Ctx<string> {
  const [username, setUsername] = useContext(UsernameContext);
  return [username, setUsername];
}

export function UsernameProvider(props: { children: ReactNode }) {
  const [username, setUsername] = useState("");

  return (
    <UsernameContext.Provider value={[username, setUsername]}>
      {props.children}
    </UsernameContext.Provider>
  );
}
