import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { GlobalStyles } from "../styles";
import { MobileStyles } from "../styles/mobileStyles";
import { Header } from "./Header";
import { primaryBlack } from "../styles/variables";

export function MobileLayoutWithActionBar(props: {
  children: ReactNode;
  title: string;
  prevButtonText: string;
  nextButtonText: string;
  disablePrevButton?: boolean;
  disableNextButton?: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
}) {
  return (
    <Viewport>
      <GlobalStyles />
      <MobileStyles />
      <Header
        title={props.title}
        prevButtonText={props.prevButtonText}
        nextButtonText={props.nextButtonText}
        disablePrevButton={props.disablePrevButton}
        disableNextButton={props.disableNextButton}
        onClickNext={props.onClickNext}
        onClickPrev={props.onClickPrev}
      />
      <ContentContainer>{props.children}</ContentContainer>
    </Viewport>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px 0;
  display: flex;
  justify-content: center;
  background-color: ${primaryBlack};
`;

const Viewport = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${primaryBlack};
`;
