import styled from "@emotion/styled";
import React, { useState } from "react";
import { LanguageDiv, LanguageDropdown } from "./LanguageDropdown";
import { useCurrentLanguage } from "../hooks/useCurrentLanguage";
import iconArrowDown from "../images/arrow-down.svg";

export function LanguagePicker() {
  const [showLanguages, setShowLanguages] = useState(false);

  return (
    <LanguageDiv onClick={() => setShowLanguages(!showLanguages)}>
      <CurrentLanguage />
      <Icon src={iconArrowDown} />
      {showLanguages && <LanguageDropdown />}
    </LanguageDiv>
  );
}

function CurrentLanguage() {
  const entry = useCurrentLanguage();

  return <Text>{entry?.text}</Text>;
}

const Text = styled.span`
  font-size: 12px;
`;

const Icon = styled.img`
  width: 12px;
  height: 6px;
  margin-left: 10px;
  margin-bottom: 2px;
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;
