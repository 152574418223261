import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getHasSSOAssociated } from "../../../requests";
import { LoginLayout } from "./LoginLayout";
import { TextField } from "../../../components/TextField";
import { PrimaryFormButton } from "../../../components/Buttons";
import { useValidation } from "../../Login/useValidation";
import { useUsername } from "../../../hooks/username";
import { SSOErrorHint, SSOLoginHint } from "../../Login/SSOLoginHint";
import { getUrlParameter } from "../../../utils/urlHelper";
import { redirectTo } from "../../../utils/redirectHelper";
import styled from "@emotion/styled";
import { getLocalStorage } from "../../../utils/storage";
import _ from "lodash";
import { brandingConfig } from "../../../branding/config";
import { TextFieldFloating } from "../../../components/TextFieldFloating";

export function LoginStepSSO(props: {
  corpEmail: string;
  isInactive: boolean;
}) {
  const { t } = useTranslation();
  const [usernameOrCorpEmail, setUsernameOrCorpEmail] = useUsername();
  const initCorpLoginError = props.isInactive
    ? t(
        "Your account is currently inactive. Please contact your {company} contact to reactivate your account.",
        { company: brandingConfig.company }
      )
    : "";
  const [corpLoginError, setCorpLoginError] = useState(initCorpLoginError);
  const { validateUsername } = useValidation();
  const validate = (values: { usernameOrCorpEmail: string }) => {
    const errors: { [key: string]: string } = {};
    validateUsername(values.usernameOrCorpEmail, errors);
    return errors;
  };
  const corpEmailFromJourneyApp = getUrlParameter(
    "corp_email",
    getUrlParameter("ReturnUrl")
  );

  return (
    <Formik
      initialValues={{
        usernameOrCorpEmail: props.corpEmail || corpEmailFromJourneyApp,
      }}
      validate={validate}
      onSubmit={async ({ usernameOrCorpEmail }, { setSubmitting }) => {
        usernameOrCorpEmail = _.trim(usernameOrCorpEmail);
        setSubmitting(false);
        setUsernameOrCorpEmail(usernameOrCorpEmail);
        const hasSSOAssociated = (
          await getHasSSOAssociated(usernameOrCorpEmail)
        ).data;
        if (!hasSSOAssociated) {
          setCorpLoginError(
            t(
              "Please enter your corporate email to sign in using Corporate Login or login using your myMobility username and password."
            )
          );
          return;
        }
        const culture = getLocalStorage("culture") || "en-US";
        setCorpLoginError("");
        redirectTo(
          `/ExternalIdentity/Login?usernameOrCorpEmail=${usernameOrCorpEmail}&returnUrl=${encodeURIComponent(
            getUrlParameter("ReturnUrl")
          )}&culture=${culture}`
        );
      }}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <LoginLayout>
          <TextFieldFloating name="usernameOrCorpEmail">
            {t("Corporate Email")}
          </TextFieldFloating>
          <StyledPrimaryFormButton
            type="submit"
            disabled={isSubmitting}
            data-testid="button-corporate-login"
          >
            {t("Corporate Login")}
          </StyledPrimaryFormButton>
          <SSOErrorHint data-testid="sso-error-hint">
            {corpLoginError}
          </SSOErrorHint>
          {!corpLoginError && (
            <SSOLoginHint data-testid="sso-login-hint">
              {t(
                "Make login easy - corporate login allows you to login using your corporate credentials so you do not need to remember your myMobility username and password."
              )}
            </SSOLoginHint>
          )}
        </LoginLayout>
      )}
    </Formik>
  );
}

const StyledPrimaryFormButton = styled(PrimaryFormButton)`
  margin-bottom: 0;
`;
