import React from "react";
import { redirectTo } from "../../utils/redirectHelper";
import { getUrlParameter } from "../../utils/urlHelper";

export function ExternalIdentity() {
  const clientId = getUrlParameter("clientId");
  const returnUrl = getUrlParameter("returnUrl") || "main";
  redirectTo(
    `/ExternalIdentity/Associate?clientId=${clientId}&returnUrl=${returnUrl}`
  );
  return <div />;
}
