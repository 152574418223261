import styled from "@emotion/styled";
import React from "react";
import checkCircle from "../../images/check-circle.svg";
import { MyMobilityH2 } from "../../components/MyMobilityH2";
import { PrimaryFormButton } from "../../components/Buttons";
import { redirectTo } from "../../utils/redirectHelper";
import { useAppConfig } from "../../hooks/appConfig";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Img = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 28px;
`;

const ResetTitle = styled(MyMobilityH2)`
  line-height: 36px;
`;

const MessageContainer = styled.div`
  margin-top: 24px;
  width: 586px;
  font-size: 16px;
  line-height: 24px;
`;

const ReturnToLogin = styled(PrimaryFormButton)`
  width: 244px;
  height: 48px;
  margin-top: 40px;
`;

export function ResetSuccess() {
  const { t } = useTranslation();
  const { myPwCEntryPoint } = useAppConfig();

  return (
    <Container>
      <Img src={checkCircle} alt="checkCircle" />
      <ResetTitle>Reset Password Successfully </ResetTitle>
      <MessageContainer>
        Your password has been successfully reset. You can now login to your
        myMobility account with your new password.
      </MessageContainer>
      <ReturnToLogin onClick={() => redirectTo(myPwCEntryPoint)}>
        {t("Return to Login")}
      </ReturnToLogin>
    </Container>
  );
}
