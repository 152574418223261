import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

export enum Phone {
  primaryPhoneNumber = "primaryPhoneNumber",
  secondaryPhoneNumber = "secondaryPhoneNumber",
  email = "email",
}

export enum TextOrCall {
  message = "message",
  voiceCall = "voiceCall",
}

export enum Email {
  primaryEmail = "primaryEmail",
  secondaryEmail = "secondaryEmail",
}

type SenderValues = {
  phone?: Phone;
  textOrCall?: TextOrCall;
  email?: Email;
};

export const SenderValuesContext = createContext<
  [SenderValues, Dispatch<SetStateAction<SenderValues>>]
>([{}, () => {}]);

export function useSenderValues(): [
  SenderValues,
  Dispatch<SetStateAction<SenderValues>>
] {
  const [senderValues, setSenderValues] = useContext(SenderValuesContext);
  return [senderValues, setSenderValues];
}

export function SenderValuesProvider(props: { children: ReactNode }) {
  const [senderValues, setSenderValues] = useState<SenderValues>({});

  return (
    <SenderValuesContext.Provider value={[senderValues, setSenderValues]}>
      {props.children}
    </SenderValuesContext.Provider>
  );
}
