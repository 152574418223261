import { useTranslation } from "react-i18next";

export function useValidation() {
  const { t } = useTranslation();

  function validateUsername(
    username: string,
    errors: { [key: string]: string },
    setErrorCallback?: Function
  ) {
    if (!username || !username.trim()) {
      errors["username"] = t("{name} is required", { name: "Username" });
      if (setErrorCallback) {
        setErrorCallback();
      }
    }
  }

  function validatePassword(
    password: string,
    errors: { [key: string]: string },
    setErrorCallback?: Function
  ) {
    if (!password) {
      errors["password"] = t("{field} is required", { field: "Password" });
      if (setErrorCallback) {
        setErrorCallback();
      }
    }
  }

  return { validateUsername, validatePassword };
}
