import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppConfig } from "../../hooks/appConfig";
import { Layout } from "../../Layout";
import { Formik } from "formik";
import { useValidation } from "../../hooks/useValidation";
import { PrimaryFormButton } from "../../components/Buttons";
import { CenteredForm } from "../../components/CenteredForm";
import { SendResetPasswordRequest } from "../../requests";
import { useUsername } from "../../hooks/username";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import styled from "@emotion/styled";
import lockImgSrc from "../../images/lock.png";
import { Group } from "../Delivery/Group";
import { brandingConfig } from "../../branding/config";
import { redirectTo } from "../../utils/redirectHelper";
import { TextFieldFloating } from "../../components/TextFieldFloating";
import { ResetSuccess } from "./ResetSuccess";

export function ResetPassword() {
  const { t } = useTranslation();
  const [username] = useUsername();
  const culture = useCurrentLanguage()?.value || "en-US";
  const { myPwCEntryPoint } = useAppConfig();
  const [showResetSuccessPopup, setShowResetSuccessPopup] = useState(false);

  const deviceLockedText = t(
    "Please contact Website Technical Support ({supportEmail}) for assistance with your account.",
    { supportEmail: brandingConfig.supportEmail }
  );

  const helpText = t(
    `For your security, your password must <br>- Be at least 8 characters long <br>- Contain at least one lowercase letter, one uppercase letter, and one number<br>- Cannot contain the username<br>`
  );

  const regex = new RegExp(
    "(?:(?:^|.*;\\s*)myMobilityData\\s*\\=\\s*([^;]*).*$)|^.*$"
  );
  const resetPasswordDataString = decodeURIComponent(
    document.cookie.replace(regex, "$1").replace(/\+/g, " ")
  );
  const resetPasswordData = !!resetPasswordDataString
    ? JSON.parse(resetPasswordDataString)
    : {};
  const defaultUsername = username || resetPasswordData.username || "";
  const ticket = resetPasswordData.ticket || "";
  const pinCode = resetPasswordData.pincode || "";

  const validate = useValidation(ticket, defaultUsername, true);

  return (
    <Layout>
      {showResetSuccessPopup ? (
        <ResetSuccess />
      ) : (
        <Formik
          initialValues={{
            username: defaultUsername,
            password: "",
            passwordConfirmation: "",
            isDeviceLocked: "",
          }}
          validate={validate}
          onSubmit={async (
            { username, password },
            { setFieldError, setSubmitting }
          ) => {
            try {
              const response = await SendResetPasswordRequest(
                ticket,
                username.trim(),
                password,
                pinCode,
                culture
              );
              setSubmitting(false);
              if (response.status === 200) {
                setShowResetSuccessPopup(true);
              }
            } catch (e) {
              const status = e.response.status;
              const errorMessage = e.response.data.message.toString();
              if (status === 403 && errorMessage.includes("PASSWORD_RESET_TICKET_EXPIRED")) {
                redirectTo("/forget-password?isLinkExpired=true");
              } else if (status === 400 && errorMessage.includes("PASSWORD_IN_HISTORY")) {
                setFieldError(
                    "password",
                    t("Your password cannot be the same as the last password")
                );
                setFieldError(
                    "passwordConfirmation",
                    t("Your password cannot be the same as the last password")
                );
              }
            }
          }}
          validateOnChange={false}
        >
          {({ isSubmitting, errors }) => {
            const isDeviceLocked = !!errors?.isDeviceLocked;
            return (
              <CenteredForm>
                <Header>
                  <Img src={lockImgSrc} alt="myMobility" />
                  <H2>{t("Reset Password")}</H2>
                </Header>
                <Group>
                  <P>
                    {t(
                      "Please note that you will be redirected to the login page after resetting your username and password."
                    )}
                  </P>
                </Group>
                <TextFieldFloating
                  name="username"
                  nonFloating={true}
                  warningMessage={isDeviceLocked ? deviceLockedText : ""}
                >
                  {t("Username")}
                </TextFieldFloating>
                <TextFieldFloating
                  name="password"
                  helpText={helpText}
                  type="password"
                  nonFloating={true}
                >
                  {t("Password")}
                </TextFieldFloating>
                <TextFieldFloating
                  name="passwordConfirmation"
                  type="password"
                  nonFloating={true}
                >
                  {t("Re-Enter Password")}
                </TextFieldFloating>
                <StyledPrimaryFormButton
                  data-testid="button-confirm-to-reset"
                  type="submit"
                  disabled={isSubmitting || isDeviceLocked}
                >
                  {t("Confirm to Reset")}
                </StyledPrimaryFormButton>
              </CenteredForm>
            );
          }}
        </Formik>
      )}
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

const Img = styled.img`
  width: 56px;
  height: 56px;
`;

const H2 = styled.h2`
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600;
`;

const P = styled.p`
  font-size: 16px;
`;

const StyledPrimaryFormButton = styled(PrimaryFormButton)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 244px;
  margin-top: 4px;
`;
