import { Global } from "@emotion/core";
import { css } from "@emotion/core";
import React from "react";
import { appBackgroundColor } from "./variables";

export const mobileStyles = css`
  body {
    background: ${appBackgroundColor};
  }
  input {
    font-size: 16px;
  }
`;

export function MobileStyles() {
  return <Global styles={mobileStyles} />;
}
