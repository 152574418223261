import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const PinCodeExpiredSecondsContext = createContext<Ctx<number>>([
  0,
  () => {},
]);

export function usePinCodeExpiredSeconds(): Ctx<number> {
  const [pinCodeExpiredSeconds, setPinCodeExpiredSeconds] = useContext(
    PinCodeExpiredSecondsContext
  );
  return [pinCodeExpiredSeconds, setPinCodeExpiredSeconds];
}

export function PinCodeExpiredSecondsProvider(props: { children: ReactNode }) {
  const [pinCodeExpiredSeconds, setPinCodeExpiredSeconds] = useState(0);

  return (
    <PinCodeExpiredSecondsContext.Provider
      value={[pinCodeExpiredSeconds, setPinCodeExpiredSeconds]}
    >
      {props.children}
    </PinCodeExpiredSecondsContext.Provider>
  );
}
