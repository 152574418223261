import styled from "@emotion/styled";
import { Field } from "formik";
import React from "react";
import { Checkbox as MuiCheckBox } from "@material-ui/core";
import {
  secondaryTealMed,
  primaryTeal,
  secondaryTealLight,
  primaryBlack,
} from "../styles/variables";

export function Checkbox(props: { name: string; children: string }) {
  return (
    <CheckboxContainer>
      <Field name={props.name}>
        {({ field, ...rest }: any) => {
          return (
            <C
              {...field}
              id={props.name}
              checked={field.value}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
            ></C>
          );
        }}
      </Field>
      <label htmlFor={props.name}>{props.children}</label>
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
`;

const C = styled(MuiCheckBox)`
  color: ${secondaryTealLight} !important;
`;

const CheckedIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${primaryTeal};
  border-radius: 100%;
  background: ${primaryBlack};
  position: relative;
  box-sizing: border-box;
  &::after {
    content: "";
    width: 9px;
    height: 9px;
    background: ${primaryTeal};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    transition: all 0.2s ease;
  }
`;

const UncheckedIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid ${primaryTeal};
  box-sizing: border-box;
`;
