import styled from "@emotion/styled";
import { Form } from "formik";
import { PrimaryButton } from "../../components/Buttons";
import { secondaryGrey2 } from "../../styles/variables";
import { getUrlParameter } from "../../utils/urlHelper";

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const FormGroup = styled.div`
  flex: 1;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ButtonContainer = styled.div`
  padding: 5px 30px;
  display: flex;
  align-self: stretch;
  justify-content: flex-end;
  border-top: 1px solid ${secondaryGrey2};
`;
export const FormButton = styled(PrimaryButton)`
  margin: 15px 0;
`;

export function getIsProxy() {
  return !!getUrlParameter("isProxy");
}

export function getInviteTicket() {
  return getUrlParameter("inviteTicket");
}

export function getCultureCode() {
  return getUrlParameter("cultureCode");
}
