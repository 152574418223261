import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Senders } from "../requests";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const PinCodeSendersContext = createContext<Ctx<Senders>>([
  {
    primaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    secondaryEmail: "",
  },
  () => {},
]);

export function usePinCodeSenders(): Ctx<Senders> {
  const [pinCodeSenders, setPinCodeSenders] = useContext(PinCodeSendersContext);
  return [pinCodeSenders, setPinCodeSenders];
}

export function PinCodeSendersProvider(props: { children: ReactNode }) {
  const [pinCodeSenders, setPinCodeSenders] = useState<Senders>({
    primaryEmail: "",
    primaryPhoneNumber: "",
    secondaryEmail: "",
    secondaryPhoneNumber: "",
  });

  return (
    <PinCodeSendersContext.Provider value={[pinCodeSenders, setPinCodeSenders]}>
      {props.children}
    </PinCodeSendersContext.Provider>
  );
}
