import React from "react";
import { redirectTo } from "../../utils/redirectHelper";
import { getUrlParameter } from "../../utils/urlHelper";
import { useMount } from "../../hooks/useMount";

export function DisconnectExternalIdentity() {
  useMount(async () => {
    const returnUrl = getUrlParameter("returnUrl");
    redirectTo(`/ExternalIdentity/Disconnect?returnUrl=${returnUrl}`);
  });
  return <div />;
}
