import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { GlobalStyles } from "../styles";
import { MobileStyles } from "../styles/mobileStyles";
import { MobileFooter } from "../pages/journey/Footer";
import { Header } from "../Layout/Header";
import { AnnouncementBanner } from "../components/AnnouncementBanner";
import { useAnnouncementBanner } from "../hooks/useAnnouncementBanner";

export function MobileLayout(props: { children: ReactNode }) {
  const { announcement } = useAnnouncementBanner();
  const { shouldShowAnnouncement, announcementContent } = announcement;
  return (
    <Viewport>
      <GlobalStyles />
      <MobileStyles />
      <Header showLanguagePicker={false} />
      {shouldShowAnnouncement && !!announcementContent && (
        <AnnouncementBanner />
      )}
      <ContentContainer>{props.children}</ContentContainer>
      <MobileFooter />
    </Viewport>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px 0;
  display: flex;
  justify-content: center;
`;

const Viewport = styled.div`
  width: 100%;
  height: 100%;
`;
