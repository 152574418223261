import React from "react";
import styled from "@emotion/styled";
import {
  zIndexHigh,
  primaryTeal,
  secondaryGrey4,
  primaryBlack,
} from "../styles/variables";
import { HelpOutline, KeyboardArrowLeft } from "@material-ui/icons";

export function Header(props: {
  title: string;
  prevButtonText: string;
  nextButtonText: string;
  disablePrevButton?: boolean;
  disableNextButton?: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
}) {
  return (
    <Div>
      <LinkButton
        disabled={props.disablePrevButton}
        onClick={props.disablePrevButton ? () => {} : props.onClickPrev}
      >
        <PrevIcon />
        <span>{props.prevButtonText}</span>
      </LinkButton>
      <Head>{props.title}</Head>
      <LinkButton
        disabled={props.disableNextButton}
        onClick={props.disableNextButton ? () => {} : props.onClickNext}
      >
        {props.nextButtonText}
      </LinkButton>
    </Div>
  );
}

const Head = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 10px;
`;

const Div = styled.div`
  background: ${primaryBlack};
  width: 100%;
  padding: 10px 15px 10px 5px;
  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${zIndexHigh};
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;

const LinkButton = styled.a<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? secondaryGrey4 : primaryTeal)};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const PrevIcon = styled(KeyboardArrowLeft)`
  color: ${primaryTeal} !important;
  height: auto !important;
  font-size: 20px !important;
  margin-left: 4px;
  color: white;
  cursor: pointer;
`;
