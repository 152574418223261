import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../components/H2";
import { useValidation } from "../../hooks/useValidation";
import { Layout } from "../../Layout";
import { registerProxy } from "../../requests";
import { redirectTo } from "../../utils/redirectHelper";
import {
  ButtonContainer,
  FormButton,
  FormContainer,
  FormGroup,
  getCultureCode,
  getInviteTicket,
} from "./common";
import { ProgressIndicator } from "./ProgressIndicator";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { brandingConfig } from "../../branding/config";
import { TextFieldFloating } from "../../components/TextFieldFloating";

export function ProxyRegistrationStep(props: {
  email: string;
  accessCode: string;
}) {
  const { t } = useTranslation();
  const validate = useValidation(getInviteTicket(), props.email);
  const deviceLockedText = t(
    "Please contact Website Technical Support ({supportEmail}) for assistance with your account.",
    { supportEmail: brandingConfig.supportEmail }
  );

  const helpText = t(
    `For your security, your password must <br>- Be at least 8 characters long <br>- Contain at least one lowercase letter, one uppercase letter, and one number<br>- Cannot contain the username<br>`
  );
  const currentCulture = useCurrentLanguage()?.value || "en-US";

  return (
    <Layout height={620} width={960}>
      <Formik
        initialValues={{
          username: props.email,
          password: "",
          passwordConfirmation: "",
          isDeviceLocked: "",
        }}
        validate={validate}
        onSubmit={async (
          { username, password },
          { setSubmitting, setFieldError }
        ) => {
          try {
            const response = await registerProxy({
              inviteTicket: getInviteTicket(),
              username: username.trim(),
              password,
              cultureCode:
                getCultureCode() === currentCulture
                  ? getCultureCode()
                  : currentCulture,
              email: props.email,
              accessCode: props.accessCode,
            });
            setSubmitting(false);
            redirectTo(response.data?.returnUrl);
          } catch (e) {
            if (e.response.status === 409) {
              setFieldError("username", t("Username has been taken"));
            }
          }
        }}
        validateOnChange={false}
      >
        {({ isSubmitting, errors }) => {
          const isDeviceLocked = !!errors?.isDeviceLocked;
          return (
            <FormContainer>
              <ProgressIndicator />

              <FormGroup>
                <H2>{t("Create your account")}</H2>

                <TextFieldFloating
                  name="username"
                  warningMessage={isDeviceLocked ? deviceLockedText : ""}
                  nonFloating
                >
                  {t("Username")}
                </TextFieldFloating>
                <TextFieldFloating
                  name="password"
                  helpText={helpText}
                  type="password"
                  nonFloating
                >
                  {t("Password")}
                </TextFieldFloating>
                <TextFieldFloating
                  name="passwordConfirmation"
                  type="password"
                  nonFloating
                >
                  {t("Re-Enter Password")}
                </TextFieldFloating>
              </FormGroup>

              <ButtonContainer>
                <FormButton
                  data-testid="button-next-on-proxy-registration"
                  type="submit"
                  disabled={isSubmitting || isDeviceLocked}
                >
                  {t("Next")}
                </FormButton>
              </ButtonContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </Layout>
  );
}
