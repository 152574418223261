import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { GlobalStyles } from "../styles";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { AnnouncementBanner } from "../components/AnnouncementBanner";
import { useAnnouncementBanner } from "../hooks/useAnnouncementBanner";
import {MediaQueryMobile, primaryBlack, MediaQueryPC} from "../styles/variables";
import { useLocation } from "react-router-dom";

export function Layout({
  width = 700,
  height = 480,
  ...props
}: {
  children: ReactNode;
  width?: number;
  height?: number;
}) {
  const { announcement } = useAnnouncementBanner();
  const { shouldShowAnnouncement, announcementContent } = announcement;
  const { pathname } = useLocation();
  const minPageHeight =
    pathname.indexOf("delivery") > -1 ||
    pathname.indexOf("connect-account") > -1
      ? 750
      : 500;

  return (
    <OuterContainer minHeight={minPageHeight}>
      <GlobalStyles />
        <TopContainer>
            {shouldShowAnnouncement && !!announcementContent && (
                <AnnouncementBanner />
            )}
            <Header/>
        </TopContainer>
      <MediumContainer width={width}>
        <InnerContainer height={height}>{props.children}</InnerContainer>
      </MediumContainer>
      <Footer />
    </OuterContainer>
  );
}

const OuterContainer = styled.div<{ minHeight: number }>`
  background-color: ${primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  position: relative;

  justify-content: space-between;

  ${MediaQueryMobile} {
    height: auto;
    padding: 24px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
`

const MediumContainer = styled.div<{ width: number }>`
  flex: 0 0 auto;

  ${MediaQueryPC} {
    width: ${(props) => `${props.width || 700}px`};
  }
  ${MediaQueryMobile} {
    width: 100%;
  }
`;

const InnerContainer = styled.div<{ height: number }>`
  position: relative;
  background: black;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: ${(props) => `${props.height || 480}px`};
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
  
  ${MediaQueryMobile} {
    height: auto;
  }
`;
