import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const ShowErrorContext = createContext<Ctx<boolean>>([true, () => {}]);

export function useShowError(): Ctx<boolean> {
  const [showError, setShowError] = useContext(ShowErrorContext);
  return [showError, setShowError];
}

export function ShowErrorProvider(props: { children: ReactNode }) {
  const [showError, setShowError] = useState(true);

  return (
    <ShowErrorContext.Provider value={[showError, setShowError]}>
      {props.children}
    </ShowErrorContext.Provider>
  );
}
