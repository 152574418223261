import styled from "@emotion/styled";
import React from "react";
import ImageAlertCircle from "../images/alert-circle.svg";
import { useAnnouncementBanner } from "../hooks/useAnnouncementBanner";
import { statusYellow3, statusYellow1, zIndexHigh, primaryBlack } from "../styles/variables";

export function AnnouncementBanner() {
  const {
    announcement: { announcementContent },
  } = useAnnouncementBanner();
  return (
    <AnnouncementBannerContainer>
        <img src={ImageAlertCircle} alt="Alert-Circle"/>
      <p
        dangerouslySetInnerHTML={{
          __html: announcementContent,
        }}
      />
    </AnnouncementBannerContainer>
  );
}

const AnnouncementBannerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 16px 32px;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: ${zIndexHigh};
  background-color: ${statusYellow1};
  border-left: 4px solid ${statusYellow3};

  img {
    width: 24px;
    height: 24px;
    margin-right: 18px;
  }
  
  p {
    font-size: 14px;
    line-height: 1.43;
    color: ${primaryBlack};
    word-break: break-word;
  }
`;
