import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryFormButton } from "../../components/Buttons";
import { CenteredForm } from "../../components/CenteredForm";
import { Checkbox } from "../../components/Checkbox";
import { Layout } from "../../Layout";
import { verifyPinCode, verifyPinCodeAndAssociate } from "../../requests";
import { Group } from "./components";
import { GoBack } from "./GoBack";
import { Headline } from "./Headline";
import { AccessCodeFieldAndResend } from "./AccessCodeFieldAndResend";
import { redirectTo } from "../../utils/redirectHelper";
import { useAppConfig } from "../../hooks/appConfig";
import { getLocalStorage } from "../../utils/storage";
import { MessagePopup } from "../../components/MessagePopup";

export function Verify(props: { associateExistingAccount?: boolean }) {
  return <InnerVerify {...props} />;
}

function useCountDown(
  initialSeconds: number,
  callback: Function
): [number, boolean, () => void] {
  const [counter, setCounter] = useState(6);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCounting) {
        if (counter > 0) setCounter((x) => x - 1);
        else callback();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter, isCounting, callback]);

  function start() {
    setIsCounting(true);
  }

  return [counter, isCounting, start];
}

export function InnerVerify(props: { associateExistingAccount?: boolean }) {
  const { t } = useTranslation();
  const { myPwCEntryPoint } = useAppConfig();
  const [returnUrl, setReturnUrl] = useState("");
  const [corpEmail, setCorpEmail] = useState("");
  const [showConnectSuccessPopup, setShowConnectSuccessPopup] = useState(false);

  const [secondsLeft, hasExceeded, startCounting] = useCountDown(6, () => {
    redirectTo(myPwCEntryPoint);
  });

  return (
    <Layout>
      {showConnectSuccessPopup && (
        <MessagePopup
          title={t("Corporate Login connected successfully")}
          content={t(
            "Your connected corporate email is: <strong>{corpEmail}</strong>. You can use your corporate email to login the next time you access myMobility.",
            { corpEmail: corpEmail }
          )}
          returnUrl={returnUrl}
          buttonText={t("Thanks, I got it")}
        />
      )}
      <Formik
        initialValues={{ accessCode: "", rememberMe: false }}
        validate={({ accessCode }) => {
          if (accessCode && accessCode.trim()) return {};
          return {
            accessCode: t("{field} is required", { field: "Access Code" }),
          };
        }}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const culture = getLocalStorage("culture") || "en-US";
            if (props.associateExistingAccount) {
              const response = await verifyPinCodeAndAssociate(
                values.accessCode?.trim(),
                values.rememberMe,
                culture
              );
              if (response.status === 200) {
                setReturnUrl(response.data.returnUrl);
                setCorpEmail(response.data.corpEmail);
                setShowConnectSuccessPopup(true);
              }
            } else {
              const response = await verifyPinCode(
                values.accessCode?.trim(),
                values.rememberMe,
                culture
              );
              if (response.status === 200) {
                redirectTo(response.data.returnUrl);
              }
            }
          } catch (e) {
            if (e.response.status === 429) {
              startCounting();
            } else {
              setFieldError("accessCode", t("Invalid access code"));
            }
          }
          setSubmitting(false);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <CenteredForm width={465}>
            <Headline />
            <AccessCodeFieldAndResend
              secondsLeft={secondsLeft}
              hasExceeded={hasExceeded}
            />

            <Group>
              <Checkbox name="rememberMe">
                {t(
                  "Remember me (you will not be required to input your access code again on this browser)"
                )}
              </Checkbox>
            </Group>

            <PrimaryFormButton
              type="submit"
              disabled={isSubmitting}
              data-testid="button-next-on-verify-code"
              className="submit-button"
            >
              {t("Login")}
            </PrimaryFormButton>

            <GoBack
              hasExceeded={hasExceeded}
              associateExistingAccount={props.associateExistingAccount}
            />
          </CenteredForm>
        )}
      </Formik>
    </Layout>
  );
}
