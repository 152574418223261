import React from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../components/H2";
import { Layout } from "../../Layout";
import { useAppConfig } from "../../hooks/appConfig";
import { redirectTo } from "../../utils/redirectHelper";
import { PrimaryFormButton } from "../../components/Buttons";
import styled from "@emotion/styled";

export function BackToLogin() {
  const { t } = useTranslation();
  const { myPwCEntryPoint } = useAppConfig();

  return (
    <Layout>
      <FormGroup>
        <H2>{t("Reset Password")}</H2>
        <P>
          {t(
            "An email will be sent to your nominated email address. The email will expire in 1 hour."
          )}
        </P>
        <FormButton
          onClick={async () => {
            redirectTo(myPwCEntryPoint);
          }}
          data-testid="button-back-to-login"
        >
          {t("Back to Login")}
        </FormButton>
      </FormGroup>
    </Layout>
  );
}

const FormGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 425px;
  padding: 5px;
`;

const FormButton = styled(PrimaryFormButton)`
  margin-top: 16px;
`;

const P = styled.p`
  margin-top: 16px;
`;
