import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useCopyrightLinks } from "../hooks/appConfig";
import {
  primaryTeal,
  primaryWhite,
  secondaryTealLight,
  smallerFontSize,
} from "../styles/variables";
import { useCurrentLanguage } from "../hooks/useCurrentLanguage";

export function Footer() {
  const {
    copyrightLink,
    securityLink,
    privacyLink,
    cookieInfoLink,
    webSupportContactEmail,
  } = useCopyrightLinks();
  const { t } = useTranslation();
  const culture = useCurrentLanguage()?.value;

  return (
    <Div>
      <P>
        <>&copy; 2022 Vialto Partners. </>
        <span>{t("All rights reserved. Please see")}</span>
        <CopyRightA href={copyrightLink} data-testid="structure-link">
          {" "}
          vialto.com/copyright{" "}
        </CopyRightA>
        <span>{t("for further details.")}</span>
      </P>
      <P>
        <A
          href={`${securityLink}?culture=${culture}`}
          target="_blank"
          data-testid="security-link"
        >
          {t("Security")}
        </A>{" "}
        <A
          href={`${privacyLink}?culture=${culture}`}
          target="_blank"
          data-testid="privacy-link"
        >
          {t("Privacy")}
        </A>{" "}
        <A
          href={`${cookieInfoLink}?culture=${culture}`}
          target="_blank"
          data-testid="cookie-info-link"
        >
          {t("Cookie Policy")}
        </A>{" "}
        <A
          href={`mailto:${webSupportContactEmail}`}
          title={`Please contact: ${webSupportContactEmail}`}
          data-testid="support-link"
        >
          {t("Support")}
        </A>
      </P>
    </Div>
  );
}

const Div = styled.div`
  flex: 0 0 auto;
  text-align: center;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  display: block;
  width: auto;
  padding: 0 0 15px 0;

  position: relative;
  bottom: auto;
  left: auto;
`;

const P = styled.p`
  color: ${primaryWhite};
  font-size: ${smallerFontSize};
  &:last-child {
    margin-bottom: 5px;
  }
`;

const A = styled.a`
  color: ${primaryTeal};
  font-size: ${smallerFontSize};
  margin-left: 4px;
  margin-right: 4px;
  &:hover {
    cursor: pointer;
    color: ${secondaryTealLight};
  }
`;

const CopyRightA = styled(A)`
  margin-left: 0;
  margin-right: 0;
`;
