import styled from "@emotion/styled";
import { Theme, Tooltip as MuiTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  secondaryGrey2,
  primaryBlack,
  fontSans,
  smallerFontSize,
} from "../styles/variables";
import iconQuestionMark from "../images/question-mark.svg";

export function Tooltip(props: { helpText: string }) {
  return (
    <HtmlTooltip
      title={<P dangerouslySetInnerHTML={{ __html: props.helpText }} />}
      placement="right"
      arrow
    >
      <HelpIcon src={iconQuestionMark} />
    </HtmlTooltip>
  );
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontFamily: fontSans,
    backgroundColor: theme.palette.common.white,
    color: primaryBlack,
    fontSize: smallerFontSize,
    maxWidth: 300,
    padding: "10px",
    border: `1px solid ${secondaryGrey2}`,
  },
  arrow: {
    color: "#ffffff",
  },
}))(MuiTooltip);

const P = styled.p`
  font-size: ${smallerFontSize};
  font-weight: normal;
`;

const HelpIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
