import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Phone, useSenderValues } from "../../../hooks/senders";
import { useUsername } from "../../../hooks/username";
import { sendLoginPinCode } from "../../../requests";
import { primaryTeal, secondaryTealLight } from "../../../styles/variables";
import { useFormikContext } from "formik";
import { AccessCodeField } from "../../../components/AccessCodeField";

export function AccessCodeFieldAndResend(props: {
  secondsLeft: number;
  hasExceeded: boolean;
}) {
  const { t } = useTranslation();
  const { setFieldError } = useFormikContext();

  useEffect(() => {
    if (props.hasExceeded) {
      setFieldError(
        "accessCode",
        t(
          "You have exceeded the maximum number of verification attempts. You will be redirected back to Login page in {countDownSeconds} seconds.",
          { countDownSeconds: props.secondsLeft }
        )
      );
    }
  }, [props.secondsLeft, props.hasExceeded, setFieldError, t]);

  return (
    <AccessCodeContainer>
      <AccessCodeField name="accessCode" />
      <ResendButton disabled={props.hasExceeded} />
    </AccessCodeContainer>
  );
}

function ResendButton(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [username] = useUsername();
  const [senderValues] = useSenderValues();

  async function handleResend() {
    const isSendingByEmail = senderValues.phone === Phone.email;
    const sendThrough = isSendingByEmail
      ? Phone.email
      : senderValues.textOrCall;
    const sender = isSendingByEmail ? senderValues.email : senderValues.phone;
    setSent(true);
    await sendLoginPinCode(username, sender, sendThrough);
  }

  if (sent) return <DisabledLink>{t("Sent")}</DisabledLink>;
  if (props.disabled) return <DisabledLink>{t("Resend Code")}</DisabledLink>;
  return (
    <ActiveLink onClick={handleResend} data-testid="resend-verify-code-link">
      {t("Resend Code")}
    </ActiveLink>
  );
}

const AccessCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const ActiveLink = styled.a`
  margin-top: 16px;
  color: ${primaryTeal};
  text-align: center;

  &:hover {
    color: ${secondaryTealLight};
  }
`;

const DisabledLink = styled.span`
  color: ${primaryTeal};
  display: flex;
  margin-bottom: -4px;
  align-self: center;
`;
