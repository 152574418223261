import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ConnectAccount } from "./pages/journey/ConnectAccount";
import { Delivery } from "./pages/journey/Delivery";
import { Login } from "./pages/journey/Login";
import { Verify } from "./pages/journey/Verify";

export default function JourneyRouter(props: { match: { path: string } }) {
  const path = props.match.path;
  return (
    <>
      <Switch>
        <Route path={`${path}/login`}>
          <Login />
        </Route>
        <Route path={`${path}/delivery`}>
          <Delivery />
        </Route>
        <Route path={`${path}/delivery-and-associate`}>
          <Delivery associateExistingAccount />
        </Route>
        <Route path={`${path}/verify`}>
          <Verify />
        </Route>
        <Route path={`${path}/verify-and-associate`}>
          <Verify associateExistingAccount />
        </Route>
        <Route path={`${path}/connect-account`}>
          <ConnectAccount />
        </Route>
      </Switch>
    </>
  );
}
