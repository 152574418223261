import Fingerprint from "fingerprintjs2";

const defaultOptions = {
  excludeFlashFonts: true,
  excludePixelRatio: true,
  excludeAdBlock: true,
  excludeWebGL: true,
  excludeAvailableScreenResolution: true,
  excludeScreenResolution: true,
  excludeTimezoneOffset: true,
  excludeTouchSupport: true,
  excludeIEPlugins: true,
  excludePlugins: true,
  excludeHasLiedBrowser: true,
  excludeHasLiedOs: true,
  excludeHasLiedResolution: true,
  excludeHasLiedLanguages: true,
  excludeOpenDatabase: true,
  excludeAddBehavior: true,
  excludeIndexedDB: true,
  excludeSessionStorage: true,
  excludeColorDepth: true,
  excludeLanguage: true,
};

export async function getFingerprint() {
  return await new Promise<string>((resolve) => {
    try {
      new Fingerprint(defaultOptions).get(resolve);
    } catch (e) {
      const options = { ...defaultOptions, excludeCanvas: true };
      new Fingerprint(options).get(resolve);
    }
  });
}
