import React from "react";
import { useTranslation } from "react-i18next";
import { BlockLink } from "../../components/BlockLink";
import styled from "@emotion/styled";

export function ForgetPasswordLink() {
  const { t } = useTranslation();

  return (
    <StyledBlockLink
      to={`/forget-password${window.location.search}`}
      data-testid="forget-psw-link"
    >
      {t("Need help logging in?")}
    </StyledBlockLink>
  );
}

const StyledBlockLink = styled(BlockLink)`
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
`;
