import styled from "@emotion/styled";
import { Form } from "formik";
import {MediaQueryMobile, MediaQueryPC} from "../styles/variables";

type Props = {
  width?: number;
};

export const CenteredForm = styled(Form)<Props>`
  box-sizing: border-box;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  display: flex;

  ${MediaQueryPC} {
    width: ${(props: Props) => `${props.width ? props.width : "465"}px`};
  }
  ${MediaQueryMobile} {
    width: 100%;
  }

  .submit-button {
    align-self: center;
    width: 244px;
    margin: 0 0 24px 0;
  }
`;
