import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../components/H2";
import { useAppConfig } from "../../hooks/appConfig";
import { useMount } from "../../hooks/useMount";
import { useValidation } from "../../hooks/useValidation";
import { Layout } from "../../Layout";
import {
  getEmailByTicket,
  registerUser,
  verifyInviteTicket,
} from "../../requests";
import { redirectTo } from "../../utils/redirectHelper";
import {
  ButtonContainer,
  FormButton,
  FormContainer,
  FormGroup,
  getCultureCode,
  getInviteTicket,
} from "./common";
import { ProgressIndicator } from "./ProgressIndicator";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { brandingConfig } from "../../branding/config";
import { TextFieldFloating } from "../../components/TextFieldFloating";

export function AssigneeRegistration() {
  const { t } = useTranslation();
  const [primaryEmail, setPrimaryEmail] = useState("");
  const validate = useValidation(getInviteTicket(), primaryEmail);
  const { myPwCEntryPoint } = useAppConfig();
  const deviceLockedText = t(
    "Please contact Website Technical Support ({supportEmail}) for assistance with your account.",
    { supportEmail: brandingConfig.supportEmail }
  );
  const currentCulture = useCurrentLanguage()?.value || "en-US";

  useMount(async () => {
    const verifyResponse = await verifyInviteTicket(getInviteTicket());
    if (!verifyResponse.data) redirectTo(myPwCEntryPoint);
    const response = await getEmailByTicket(getInviteTicket());
    setPrimaryEmail(response?.data?.primaryEmail);
  });

  const helpText = t(
    `For your security, your password must <br>- Be at least 8 characters long <br>- Contain at least one lowercase letter, one uppercase letter, and one number<br>- Cannot contain the username<br>`
  );

  return (
    <Layout height={620} width={960}>
      {primaryEmail && (
        <Formik
          initialValues={{
            username: primaryEmail,
            password: "",
            passwordConfirmation: "",
            isDeviceLocked: "",
          }}
          validate={validate}
          onSubmit={async (
            { username, password },
            { setSubmitting, setFieldError }
          ) => {
            try {
              const response = await registerUser(
                getInviteTicket(),
                username.trim(),
                password,
                getCultureCode() === currentCulture
                  ? getCultureCode()
                  : currentCulture
              );
              setSubmitting(false);
              redirectTo(response.data?.returnUrl);
            } catch (e) {
              if (e.response.status === 409) {
                setFieldError("username", t("Username has been taken"));
              }
            }
          }}
          validateOnChange={false}
        >
          {({ isSubmitting, errors }) => {
            const isDeviceLocked = !!errors?.isDeviceLocked;
            return (
              <FormContainer>
                <ProgressIndicator />

                <FormGroup>
                  <H2>{t("Create your account")}</H2>

                  <TextFieldFloating
                    nonFloating
                    name="username"
                    warningMessage={isDeviceLocked ? deviceLockedText : ""}
                  >
                    {t("Username")}
                  </TextFieldFloating>
                  <TextFieldFloating
                    nonFloating
                    name="password"
                    helpText={helpText}
                    type="password"
                  >
                    {t("Password")}
                  </TextFieldFloating>
                  <TextFieldFloating
                    nonFloating
                    name="passwordConfirmation"
                    type="password"
                  >
                    {t("Re-Enter Password")}
                  </TextFieldFloating>
                </FormGroup>

                <ButtonContainer>
                  <FormButton
                    data-testid="button-next-on-registration"
                    type="submit"
                    disabled={isSubmitting || isDeviceLocked}
                  >
                    {t("Next")}
                  </FormButton>
                </ButtonContainer>
              </FormContainer>
            );
          }}
        </Formik>
      )}
    </Layout>
  );
}
