// @ts-nocheck
import { useAppConfig } from "../hooks/appConfig";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export function Analytics() {
  const { applicationInsightsConnectionString } = useAppConfig();

  if (applicationInsightsConnectionString && !window.appInsights) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: applicationInsightsConnectionString,
        enableAutoRouteTracking: true,
      },
    });
    appInsights.loadAppInsights();
  } 
  return null;
}
 