import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  checkUsernameUnique,
  getDeviceLockedStatus,
  isFromSameAccount,
  validatePasswordAsync,
} from "../requests";
import { useShowError } from "./showError";

let lastCheckedUsernameUnique: { [index: string]: boolean } = {};
let lastCheckedIsFromSameAccount: { [index: string]: boolean } = {};

export function useValidation(
  ticket: string,
  defaultUsername: string,
  withDefaultCorpEmail: boolean = false
) {
  const { t } = useTranslation();
  const [, setShowError] = useShowError();

  async function validateUsername(username: string) {
    if (!username) return t("{name} is required", { name: "Username" });
    if (!isUsernameValid(username))
      return t(
        "Invalid username. Only allow number, letter, hyphen, space, underscore, at sign, period and cannot begin or end with a period."
      );
    if (username in lastCheckedUsernameUnique) {
      return lastCheckedUsernameUnique[username]
        ? null
        : t("Username has been taken");
    }
    return null;
  }

  async function shouldValidateUsernameUnique(username: string, ticket: string) {
    if (defaultUsername.toLowerCase() === username.toLowerCase()) return false;
    if (defaultUsername && withDefaultCorpEmail) {
      if (await validateIsFromSameAccount(username, ticket)) return false;
    }
    return !(username in lastCheckedUsernameUnique);
  }

  async function validateIsFromSameAccount(username: string, ticket: string) {
    if (username in lastCheckedIsFromSameAccount) {
      return lastCheckedIsFromSameAccount[username];
    }
    const isFromSame = (await isFromSameAccount(username, ticket))
      .data;
    lastCheckedIsFromSameAccount = { [username]: isFromSame };
    return isFromSame;
  }

  async function validateUsernameUnique(username: string) {
    const isUnique = (await checkUsernameUnique(username, ticket)).data;
    lastCheckedUsernameUnique = { [username]: isUnique };
    if (!isUnique) return t("Username has been taken");
  }

  async function validatePassword(username: string, password: string) {
    if (!password) return t("{field} is required", { field: "Password" });
    if (isPasswordInvalid(password)) return t("Invalid password format.");
    const response = await validatePasswordAsync(username, password);
    if (response.data === "InvalidPasswordFormat")
      return t("Invalid password format");
    if (response.data === "PasswordIsTooCommon")
      return t("The password is too common");
  }

  function validateConfirmationPassword(
    password: string,
    confirmationPassword: string
  ) {
    if (
      confirmationPassword &&
      isConfirmPasswordInvalid(password, confirmationPassword)
    ) {
      return t("These passwords don’t match.");
    } else if (!confirmationPassword) {
      return t("{field} is required", { field: "Re-Enter Password" });
    }
  }

  return async function validate(values: {
    username: string;
    password: string;
    passwordConfirmation: string;
    isDeviceLocked: string;
  }) {
    setShowError(false);

    const errors: { [key: string]: string } = {};
    const trimmedUsername = _.trim(values?.username);
    const usernameValidationError = await validateUsername(trimmedUsername);
    if (usernameValidationError) {
      errors["username"] = usernameValidationError;
    } else {
      const isDeviceLocked = (await getDeviceLockedStatus()).data;
      if (isDeviceLocked) {
        errors["isDeviceLocked"] = "true";
        return errors;
      }
      const isUsernameChangedOrNotCached = await shouldValidateUsernameUnique(
          trimmedUsername,
          ticket
      );
      if (isUsernameChangedOrNotCached) {
        const usernameUniqueValidationError = await validateUsernameUnique(
          trimmedUsername
        );
        if (usernameUniqueValidationError) {
          errors["username"] = usernameUniqueValidationError;
        }
      }
    }

    const passwordValidationError = await validatePassword(
      trimmedUsername,
      values?.password
    );
    if (passwordValidationError) errors["password"] = passwordValidationError;

    const confirmationPasswordValidationError = validateConfirmationPassword(
      values?.password,
      values?.passwordConfirmation
    );
    if (confirmationPasswordValidationError)
      errors["passwordConfirmation"] = confirmationPasswordValidationError;
    setShowError(true);

    return errors;
  };
}

function isPasswordInvalid(value: string) {
  const trimmedValue = value?.trim();
  return (
    trimmedValue?.length < 8 || !/^(?=.*\d)(?=.*[a-zA-Z])/.exec(trimmedValue)
  );
}

function isUsernameValid(value: string) {
  return /^(?!\.)(?!.*\.$)[0-9a-zA-Z\- _@.]+$/.test(value);
}

function isConfirmPasswordInvalid(value: string, matchedValue: string) {
  return matchedValue !== value;
}
