import React, { Suspense, useState } from "react";
import { Layout } from "../../Layout";
import { LoginSteps } from "../../constants";
import { LoginStepStart } from "./LoginStepStart";
import { LoginStepSSO } from "./LoginStepSSO";
import { LoginStepNormalWithSSO } from "./LoginStepNormalWithSSO";
import { getUrlParameter } from "../../utils/urlHelper";

export function Login() {
  const redirectToSSOLogin = getUrlParameter("redirectToSSOLogin");
  const initStep = redirectToSSOLogin ? LoginSteps.SSOLogin : LoginSteps.Start;
  const [step, setStep] = useState(initStep);
  const corpEmail = getUrlParameter("corpEmail");

  return (
    <Layout>
      <Suspense fallback="">
        {step === LoginSteps.Start && <LoginStepStart setStep={setStep} />}
        {step === LoginSteps.SSOLogin && (
          <LoginStepSSO
            setStep={setStep}
            corpEmail={corpEmail}
            isInactive={!!corpEmail}
          />
        )}
        {step === LoginSteps.NormalLoginWithSSO && <LoginStepNormalWithSSO />}
      </Suspense>
    </Layout>
  );
}
