import React from "react";
import { insertPosthog } from "../utils/posthog";
import { useAppConfig } from "../hooks/appConfig";

export function PosthogAnalytics() {
  const { posthogApiHostUrl, posthogApiKey } = useAppConfig();

  insertPosthog(posthogApiHostUrl, posthogApiKey);
  return null;
}
